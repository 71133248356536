<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ customizePageClone.name }}
        </h2>
        <button v-on:click="onSubmit()" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button" :disabled="form.loading">
            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
            <span v-if="form.loading" class="indicator-progress">
                {{ $t("messages.wait") }}
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
        </button>
    </div>
    <div class="card" v-if="tabSkeleton">
        <div class="card-body d-flex flex-column p-9">
            <el-skeleton />
        </div>
    </div>
    <div v-else class="card mb-5 mb-xl-10" v-for="(item, itemIndex) in this.form.data.items" :key="itemIndex">
        <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" :data-bs-target="'#kt_item_' + (itemIndex + 1)" aria-expanded="true" aria-controls="kt_endpoints">
            <div class="card-title m-0">
                <h3 class="fw-bolder m-0">{{ item.name }}</h3>
            </div>
        </div>
        <div :id="'kt_item_' + (itemIndex + 1)" class="collapse show">
            <div class="card-body border-top px-9 pt-3 pb-4">
                <Item :item="item.data" :itemKey="'data'" :schema="customizePageItemsSchema[itemIndex].data" :depth="0"></Item>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <button v-on:click="onSubmit()" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button" :disabled="form.loading">
            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
            <span v-if="form.loading" class="indicator-progress">
                {{ $t("messages.wait") }}
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
        </button>
    </div>
</template>

<script>
import Item from "@/components/ecommerce/product/customize-page/item";

export default {
    name: "index",
    props: ['productID', 'customizePage'],
    components: {
        Item
    },
    data() {
        return {
            tabSkeleton: true,
            customizePageClone: {},
            customizePageItemsSchema: [],
            fetchData: {},
            form: {
                loading: false,
                data: {}
            },
            images: {},
            uploadImages: {}
        }
    },
    provide() {
        return {
            getProvide: this.getProvide,
            setProvide: this.setProvide,
            deleteProvide: this.deleteProvide
        }
    },
    created() {
        let customizePageSchema = {};

        this.customizePageClone = this.cloneData(this.customizePage);

        this.customizePageClone.items.forEach((item) => {
            customizePageSchema[item.code] = {
                code: item.code,
                name: item.name,
                data: this.convertSchema(item.schema)
            };
        });

        this.customizePageItemsSchema = customizePageSchema;

        this.loadPageDetail();
    },
    methods: {
        initialize(){
            this.form.data = {
                page: this.customizePageClone.page,
                items: JSON.parse(JSON.stringify(this.customizePageItemsSchema)),
                page_relation_id: this.productID
            };

            if (Object.keys(this.fetchData).length) {
                let schemaItems = {};

                for (let code in this.customizePageItemsSchema) {
                    schemaItems[code] = this.customizePageItemsSchema[code].data;
                }

                this.fetchData = JSON.parse(JSON.stringify(this.prepareFetchData(this.fetchData, schemaItems)));

                for (let index in this.fetchData) {
                    this.form.data.items[index].data = this.fetchData[index];
                }
            }
        },
        loadPageDetail(){
            this.tabSkeleton = true;

            this.axios.get(this.endpoints['theme_customize'] + '/fetch/' + this.customizePageClone.page + '/' + this.productID).then((response) => {
                this.fetchData = response.data.items;
                this.initialize();
            }).finally(() => {
                this.tabSkeleton = false;
            })
        },
        convertSchema(schema) {
            if (typeof (schema) == 'object' && !Array.isArray(schema)) {
                for (let index in schema) {
                    if (typeof (schema[index]) == 'object') {
                        schema[index] = this.convertSchema(schema[index]);
                    }
                    if (typeof (schema[index]) == 'string') {
                        if (schema[index] == 'string') {
                            schema[index] = {
                                type: 'string'
                            };
                        }
                        if (schema[index] == 'editor') {
                            schema[index] = {
                                type: 'editor'
                            };
                        }
                        if (schema[index] == 'image') {
                            schema[index] = {
                                type: 'image',
                                value: {}
                            };
                        }
                    }
                }
            }

            if (Array.isArray(schema)) {
                schema[0] = this.convertSchema(schema[0]);
            }

            return schema;
        },
        itemConvertToFormData(item) {
            if (typeof (item) == 'object' && !Array.isArray(item)) {
                for (let index in item) {
                    if (item[index].type) {
                        if (['string', 'editor'].includes(item[index].type)) {
                            item[index] = item[index].value ?? null;
                        } else if (item[index].type == 'image') {
                            if (item[index].value.unique && this.uploadImages[item[index].value.unique]) {
                                item[index] = this.uploadImages[item[index].value.unique][0].image_id
                            } else if (item[index].value.image_id) {
                                item[index] = item[index].value.image_id
                            } else {
                                item[index] = null;
                            }
                        }
                    } else {
                        item[index] = this.itemConvertToFormData(item[index]);
                    }
                }
            }

            if (Array.isArray(item)) {
                for (let i = 0; i < item.length; i++) {
                    item[i] = this.itemConvertToFormData(item[i]);
                }
            }

            return item;
        },
        onSubmit() {
            this.form.loading = true;

            if (Object.keys(this.uploadImages).length > 0) {
                this.submitFormWithUpload();
            } else {
                this.submitForm();
            }
        },
        submitFormWithUpload() {
            const formData = new FormData();

            for (let index in this.uploadImages) {
                formData.append("file[]", this.uploadImages[index][0].raw);
            }

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;

                    Object.entries(this.uploadImages).forEach(([key, value], index) => {
                        this.uploadImages[key][0].image_id = data[index].id;
                    });

                    this.submitForm();
                }, () => {
                    this.form.loading = false;
                }, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm() {
            let formData = this.prepareFormData();
            this.axios.post(this.endpoints['theme_customize'], formData).then(response => {
                this.onResponse(response.data, () => {
                    this.images = {};
                    this.uploadImages = {};
                    this.form.data = {};
                    this.loadPageDetail();
                }, () => {
                    this.form.loading = false;
                });
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        prepareFormData() {
            let formData = JSON.parse(JSON.stringify(this.form.data));
            let items = [];

            for (let index in formData.items) {
                let item = formData.items[index];
                items.push({
                    code: item.code,
                    data: this.itemConvertToFormData(item.data)
                });
            }

            formData.items = items;

            return formData;
        },
        prepareFetchData(data, schema){
            if (typeof (schema) == 'object' && !Array.isArray(schema) && !schema.type) {
                if(typeof (data) != 'object' || Array.isArray(data)) {
                    data = schema;
                }
                else {
                    let dataKeys = Object.keys(data);

                    for(let index in schema){
                        let x = dataKeys.indexOf(index);

                        if(x == -1){
                            data[index] = schema[index];
                        } else {
                            data[index] = this.prepareFetchData(data[index], schema[index]);
                            dataKeys.splice(x, 1);
                        }
                    }

                    if(dataKeys.length){
                        dataKeys.forEach((dataKey) => {
                            delete data[dataKey];
                        })
                    }
                }
            }

            if(Array.isArray(schema)) {
                if(!Array.isArray(data) || !data.length) {
                    data = schema;
                } else {
                    for (let i = 0; i < data.length; i++){
                        data[i] = this.prepareFetchData(data[i], schema[0]);
                    }
                }
            }

            return data;
        },
        getProvide(variable, key, column){
            if(column){
                return this[variable][key][column]
            }

            return this[variable][key];
        },
        setProvide(variable, key, value, column){

            if(!this[variable][key]){
                this[variable][key] = {};
            }

            if(column){
                this[variable][key][column] = value;
            } else {
                this[variable][key] = value;
            }
        },
        deleteProvide(variable, key){
            delete this[variable][key];
        }
    }
}
</script>

<style scoped>

</style>