<template>
    <div class="card" v-if="skeleton">
        <div class="card-body d-flex flex-column p-9">
            <el-skeleton />
        </div>
    </div>
    <custom-table
        v-else
        :title="$t('pages.ecommerce.productManagement.product.packageItemSettings.title')"
        :subTitle="$t('pages.ecommerce.productManagement.product.packageItemSettings.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow">
        <template v-slot:title="{ row: record }">
            <a class="fw-bold text-gray-600 text-hover-primary" :href="$root.appUrl + '/' + record.product.defaultLanguageTranslate.slug" target="_blank">{{ record.product.defaultLanguageTranslate.title }}</a>
        </template>
        <template v-slot:price="{ row: record }">
            {{ record.defaultCurrencyPrice.price ? record.defaultCurrencyPrice.price +  " " + $root.defaultCurrency.suffix : "-" }}
        </template>
        <template v-slot:discountPrice="{ row: record }">
            {{ record.defaultCurrencyPrice.discount_price ? record.defaultCurrencyPrice.discount_price + " " + $root.defaultCurrency.suffix : "-" }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchPackageItem(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_package_item" ref="addPackageItemModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="packageItemForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                             data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7" v-if="!form.updateStatus">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.packageItemSettings.cols.packageItem') }}</label>
                                <el-form-item prop="package_item_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.package_item_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable @change="selectedPackageItem">
                                        <el-option v-for="(packageItem, packageItemIndex) in addablePackageItems" :key="packageItemIndex" :value="packageItem.id" :label="packageItem.translate.title + ' (' + packageItem.group.name + ')'"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="row">
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">
                                        {{ $t('pages.ecommerce.productManagement.product.packageItemSettings.cols.price') }}
                                        <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.productManagement.product.packageItemSettings.informationBoxes.price')" placement="top">
                                            <span class="svg-icon svg-icon-1">
                                                <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                            </span>
                                        </el-tooltip>
                                    </label>
                                    <el-form-item prop="prices[0].price">
                                        <el-input v-model="form.data.prices[0].price" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                    </el-form-item>
                                </div>
                                <div class="col-md-6 fv-row mb-1" v-if="form.data.prices[0].price && form.data.prices[0].price.length">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.packageItemSettings.cols.discountPrice') }}</label>
                                    <el-form-item prop="prices[0].discount_price">
                                        <el-input v-model="form.data.prices[0].discount_price" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                    </el-form-item>
                                </div>
                            </div>

                            <template v-if="form.selectedPackageItem.type_id == 2">
                                <div class="row">
                                    <div class="fv-row mb-1" v-bind:class="{'col-6': form.data.usage_type === 2 }">
                                        <label class="fs-6 fw-bold mb-2">
                                            {{ $t('pages.ecommerce.productManagement.product.packageItemSettings.cols.usageType') }}

                                            <el-tooltip class="item" effect="dark" popper-class="max-w-300px" :content="$t('pages.ecommerce.productManagement.product.packageItemSettings.informationBoxes.usageType')" placement="top">
                                                <span class="svg-icon svg-icon-1">
                                                    <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                                </span>
                                            </el-tooltip>
                                        </label>

                                        <el-form-item prop="usage_type">
                                            <el-select v-model="form.data.usage_type" class="w-100" :placeholder="$t('common.chooseSelect')" @change="selectedUsageType">
                                                <el-option v-for="(usageType, usageTypeIndex) in usageTypes" :key="usageTypeIndex" :value="usageType" :label="$t('pages.ecommerce.productManagement.product.packageItemSettings.usageType.' + usageTypeIndex)"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>

                                    <div class="col-6 fv-row mb-1" v-if="form.data.usage_type === 2">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.packageItemSettings.cols.usageMaxDate') }}</label>
                                        <el-form-item prop="usage_max_day">
                                            <el-input v-model="form.data.usage_max_day" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                        </el-form-item>
                                    </div>
                                </div>

                                <template v-if="form.data.usage_type === 1">
                                    <div class="fv-row mb-1">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.packageItemSettings.cols.usageMinDate') }}</label>
                                        <el-form-item prop="usage_min_date">
                                            <el-date-picker
                                                class="rangeDateTimePicker"
                                                v-model="form.data.usage_min_date"
                                                type="datetime"
                                                popper-class="rangeDateTimePickerPopper"
                                                :placeholder="$t('common.chooseDateAndTime')"
                                                valueFormat="YYYY-MM-DD HH:mm:ss"
                                                :disabledDate="disabledUsageMinDate">
                                            </el-date-picker>
                                        </el-form-item>
                                    </div>
                                    <div class="fv-row mb-1">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.packageItemSettings.cols.usageMaxDate') }}</label>
                                        <el-form-item prop="usage_max_date">
                                            <el-date-picker
                                                class="rangeDateTimePicker"
                                                v-model="form.data.usage_max_date"
                                                type="datetime"
                                                popper-class="rangeDateTimePickerPopper"
                                                :placeholder="$t('common.chooseDateAndTime')"
                                                valueFormat="YYYY-MM-DD HH:mm:ss"
                                                :disabledDate="disabledUsageMaxDate">
                                            </el-date-picker>
                                        </el-form-item>
                                    </div>
                                </template>
                            </template>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.packageItemSettings.cols.quantity') }}</label>
                                <el-form-item prop="quantity" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.quantity" :min="1"/>
                                </el-form-item>
                            </div>

                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    props: ['productID'],
    name: "PackageItem",
    inject: ['loadCustomizePage'],
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.productManagement.product.packageItemSettings.cols.id"),
                    key: "id"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.title"),
                    scopedSlots: {customRender: "title"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.group"),
                    key: "product.group.name"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.type"),
                    key: "product.type.name"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.packageItemSettings.cols.quantity"),
                    key: "quantity"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.price"),
                    scopedSlots: {customRender: "price"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.discountPrice"),
                    scopedSlots: {customRender: "discountPrice"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                selectedPackageItem: {},
                data: {
                    prices: [{currency_id: this.$root.defaultCurrency.id}],
                }
            },
            selectedRowKeys: [],
            usageTypes: {
                fixed_date: 1,
                custom_day: 2
            },
            table: {
                data: [],
                loading: false
            },
            skeleton: true,
        }
    },
    computed: {
        packageItems() {
            let packageItems = [];
            let products = this.$store.state.ecommerce.productManagement.product.indexRaw.table.data;

            for (let i = 0; i < products.length; i++) {
                if (products[i].group.code != 'PACKAGE') {
                    products[i].translate = this.resolveDatum(products[i].translations, this.$root.defaultLanguage.id, 'language_id');
                    packageItems.push(products[i]);
                }
            }

            return packageItems;
        },
        addablePackageItems() {
            let addablePackageItems = [];
            let packageItems = this.packageItems;
            let addedPackageItemIDs = this.table.data.map((data) => {
                return data.product_package_id;
            });

            for (let i = 0; i < packageItems.length; i++) {
                if (!addedPackageItemIDs.includes(packageItems[i].id)) {
                    addablePackageItems.push(packageItems[i]);
                }
            }

            return addablePackageItems;
        }
    },
    mounted() {
        this.loadProduct();
        this.$store.dispatch('ecommerce/productManagement/product/indexRaw/get', {
            page: { pageSize: 9999 }
        }).then(() => {
            this.skeleton = false;
        });
    },
    methods: {
        loadProduct() {
            this.table.loading = true;

            this.axios.get(this.endpoints['ecommerce_product'] + '/' + this.productID).then((response) => {
                let data = response.data.data;

                this.loadCustomizePage(data.customize_page_name);

                this.table.data = data.package_items.map((packageItem) => {
                    packageItem.product.defaultLanguageTranslate =  this.resolveDatum(packageItem.product.translations, this.$root.defaultLanguage.id, 'language_id');
                    packageItem.defaultCurrencyPrice =  (packageItem.prices && packageItem.prices.length) ? this.resolveDatum(packageItem.prices, this.$root.defaultCurrency.id, 'currency_id') : {};

                    return packageItem;
                });
            }).finally(() => {
                this.table.loading = false;
            });
        },
        newPackageItem() {
            this.form.updateStatus = false;
            this.form.data = {
                product_id: this.productID,
                prices: [{currency_id: this.$root.defaultCurrency.id}],
                quantity: 1
            };
            this.form.selectedPackageItem = {};
            this.form.title = this.$t("pages.ecommerce.productManagement.product.packageItemSettings.newPackageItem");
            this.showModal(this.$refs.addPackageItemModal);
        },
        fetchPackageItem(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.ecommerce.productManagement.product.packageItemSettings.editPackageItem");
            this.axios.get(this.endpoints['ecommerce_product_package_item'] + '/' + record.id).then(response => {
                let data = response.data.data;

                data.prices = data.prices.length ? data.prices : [{currency_id: this.$root.defaultCurrency.id}];
                data.package_item_id = data.product_package_id;
                data.usage_min_date = data.usage_min_date ? this.$moment(data.usage_min_date).format('YYYY-MM-DD HH:mm:ss') : undefined;
                data.usage_max_date = data.usage_max_date ? this.$moment(data.usage_max_date).format('YYYY-MM-DD HH:mm:ss') : undefined;

                this.form.data = data;

                this.selectedPackageItem();
                this.showModal(this.$refs.addPackageItemModal);
            });
        },
        onSubmit() {
            this.$refs.packageItemForm.validate((valid) => {
                if (valid) {
                    let formData = this.preparePackageItemFormData();
                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['ecommerce_product_package_item'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadProduct();
                                this.hideModal(this.$refs.addPackageItemModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['ecommerce_product_package_item'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadProduct();
                                this.hideModal(this.$refs.addPackageItemModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("ecommerce/productManagement/product/packageItem/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if(successDeleted) {
                    this.loadProduct();
                }
            });
        },
        preparePackageItemFormData() {
            let formData = JSON.parse(JSON.stringify(this.form.data));

            if (!(formData.prices[0].price && formData.prices[0].price.length)) {
                formData.prices = [];
            }

            return formData;
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadProduct();
                    break;

                case "new":
                    this.newPackageItem();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        selectedPackageItem(){
            if(this.form.data.package_item_id) {
                for(let index in this.packageItems){
                    let packageItem = this.packageItems[index];
                    if(packageItem.id == this.form.data.package_item_id) {
                        this.form.selectedPackageItem = packageItem;
                        break;
                    }
                }
            }
        },
        disabledUsageMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.form.data.usage_max_date ? this.$moment(this.form.data.usage_max_date) : undefined;

            return endDate != undefined && startDate.valueOf() > endDate.valueOf();
        },
        disabledUsageMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.form.data.usage_min_date ? this.$moment(this.$moment(this.form.data.usage_min_date).format('YYYY-MM-DD')) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
        selectedUsageType(){
            if(this.form.data.usage_type === 1){
                this.form.data.usage_max_day = null;
            } else  if(this.form.data.usage_type === 2){
                this.form.data.usage_min_date = null;
                this.form.data.usage_max_date = null;
            }
        }
    }
}
</script>

<style scoped>

</style>