<template>
    <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
        <div class="col-md-3">
            <div class="bg-white">
                <div class="px-8 py-7">
                    <h2 class="fw-bold text-dark mb-0">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.messages.title') }}</h2>
                </div>
                <div class="separator separator-solid"></div>
                <div class="rounded h-100 px-8 py-10 pt-2">
                    <div class="pt-1 fs-7">
                        <div class="d-flex align-items-center" v-for="index in 2" :key="index" :class="index != 0 && 'mt-4'">
                        <span class="svg-icon svg-icon-2 svg-icon-success me-3">
                            <inline-svg src="/media/icons/duotune/general/gen043.svg"/>
                        </span>
                            <span class="fw-semobold text-gray-700 flex-grow-1" v-html="$t('pages.ecommerce.productManagement.product.lessonCalendarSettings.messages.message' + index)"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-7">
                <div class="card-header pt-4 pb-4">
                    <h3 class="card-title align-items-start flex-column">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.emptyLesson.title') }}</h3>
                </div>
                <div class="card-body d-flex flex-column p-9">
                    <div class="fv-row mb-1">
                        <div id="external-events" ref="externalEvents">
                            <div class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
                                <div class="fc-event-main text-center">
                                    <div class="fc-event-main-frame">
                                        <div class="fc-event-title-container">
                                            <div class="fc-event-title fc-sticky p-2 fs-7">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.emptyLesson.buttonText') }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-form :model="form.repeatFormData" ref="repeatlessonCalendarForm">
                <div class="card mt-7">
                    <div class="card-header pt-4 pb-4">
                        <h3 class="card-title align-items-start flex-column fs-6">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.addRepeatLesson') }}</h3>
                    </div>
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.cols.teacher') }}</label>
                            <el-form-item prop="teacher_id">
                                <el-select v-model="form.repeatFormData.teacher_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable>
                                    <el-option v-for="(teacher, teacherIndex) in teachers" :key="teacherIndex" :value="teacher.id" :label="teacher.firstname + ' ' + teacher.lastname">{{ teacher.firstname + ' ' + teacher.lastname }}</el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.cols.date')  }}</label>
                            <el-form-item prop="date" :rules="$validation.getMessage(['required'])">
                                <el-date-picker
                                    class="rangeDateTimePicker"
                                    v-model="form.repeatFormData.date"
                                    type="datetime"
                                    popper-class="rangeDateTimePickerPopper"
                                    :placeholder="$t('common.chooseDateAndTime')"
                                    valueFormat="YYYY-MM-DD">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.cols.startTime') }}</label>
                            <el-form-item prop="start_time" :rules="$validation.getMessage(['required'])">
                                <el-time-picker v-model="form.repeatFormData.start_time" :placeholder="$t('common.chooseTime')" valueFormat="HH:mm" format="HH:mm"></el-time-picker>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{  $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.cols.endTime')  }}</label>
                            <el-form-item prop="end_time" :rules="$validation.getMessage(['required'])">
                                <el-time-picker v-model="form.repeatFormData.end_time" :placeholder="$t('common.chooseTime')" valueFormat="HH:mm" format="HH:mm"></el-time-picker>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.cols.repeatCount') }}</label>
                            <el-form-item prop="repeat_count" :rules="$validation.getMessage(['required'])">
                                <el-input-number v-model="form.repeatFormData.repeat_count" :min="1"/>
                            </el-form-item>
                        </div>
                        <div class="fv-row mt-5">
                            <el-form-item class="mb-0">
                                <button @click.prevent="onSubmitRepeatForm" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.add") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </el-form>
            <div class="card mt-7">
                <div class="card-header pt-4 pb-4">
                    <h3 class="card-title align-items-start flex-column">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.deleteAll') }}</h3>
                </div>
                <div class="card-body d-flex flex-column p-9">
                    <div class="fv-row mb-1">
                        <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecordAll">
                            <template #reference>
                                <button class="btn btn-lg btn-danger w-100" type="button">
                                    <span>{{ $t("btn.delete") }}</span>
                                </button>
                            </template>
                        </el-popconfirm>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="card">
                <div class="card-body">
                    <FullCalendar v-if="showCalendar" class="demo-app-calendar" :options="calendarOptions"></FullCalendar>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_lesson_calendar" ref="lessonCalendarModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitLessonCalendar()" :model="form.data" ref="lessonCalendarForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.cols.teacher') }}</label>
                                <el-form-item prop="teacher_id">
                                    <el-select v-model="form.data.teacher_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable>
                                        <el-option v-for="(teacher, teacherIndex) in teachers" :key="teacherIndex" :value="teacher.id" :label="teacher.firstname + ' ' + teacher.lastname">{{ teacher.firstname + ' ' + teacher.lastname }}</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.cols.startDate') }}</label>
                                <el-form-item prop="start_date" :rules="$validation.getMessage(['required'])">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="form.data.start_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                        :disabledDate="disabledStartDate"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.lessonCalendarSettings.cols.endDate') }}</label>
                                <el-form-item prop="end_date" :rules="$validation.getMessage(['required'])">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="form.data.end_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                        :disabledDate="disabledEndDate"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                        <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord">
                            <template #reference>
                                <button class="btn btn-lg btn-danger" type="button">
                                    <span>{{ $t("btn.delete") }}</span>
                                </button>
                            </template>
                        </el-popconfirm>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import trLocale from '@fullcalendar/core/locales/tr';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

export default {
    name: "index",
    props: ['productID'],
    components: {
        FullCalendar
    },
    data() {
        return {
            form: {
                updateStatus: false,
                loading: false,
                data: {},
                title: '',
                repeatFormData: {
                    repeat_count: 3
                }
            },
            calendarOptions: {},
            showCalendar: false,
            todayDate: {},
            product: {
                translate: {}
            }
        }
    },
    computed: {
        lessonCalendars() {
            return this.$store.state.module.lessonCalendar.table.data;
        },
        teachers() {
            return this.$store.state.module.teacher.table.data;
        },
    },
    created(){
        let todayDate = this.$moment().startOf("day");
        this.todayDate = {
            yesterday: todayDate.clone().subtract(1, "day").format("YYYY-MM-DD"),
            today: todayDate.format("YYYY-MM-DD"),
            tomorrow: todayDate.clone().add(1, "day").format("YYYY-MM-DD")
        };
    },
    mounted(){
        new Draggable(this.$refs.externalEvents, {
            itemSelector: '.fc-event',
            eventData: (eventEl) => {
                return {
                    title: this.$t('pages.ecommerce.productManagement.product.lessonCalendarSettings.emptyLesson.subTitle')
                };
            }
        });
        this.loadProduct();
        this.$store.dispatch('module/teacher/get', {
            page: {pageSize: 9999}
        });
    },
    methods: {
        loadProduct() {
            this.axios.get(this.endpoints['ecommerce_product'] + '/' + this.productID).then((response) => {
                let data = response.data.data;
                data.translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id')
                this.product = data;

                this.initializeLessonCalendar();
            })
        },
        initializeLessonCalendar(){
            this.showCalendar = false;

            this.$store.dispatch('module/lessonCalendar/get', {
                page: {
                    pageSize: 9999
                },
                filterData: {
                    product_id: this.productID
                }
            }).then((lessonCalendars) => {
                let events = lessonCalendars.map((lessonCalendar) => {
                    return {
                        id: lessonCalendar.id,
                        title: this.product.translate.title,
                        start: lessonCalendar.start_date,
                        end: lessonCalendar.end_date
                    };
                });

                this.calendarOptions = {
                    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
                    headerToolbar: {
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                    },
                    initialDate: this.todayDate.today,
                    navLinks: true, // can click day/week names to navigate views
                    selectable: true,
                    selectMirror: true,
                    droppable: true,
                    views: {
                        dayGridMonth: { buttonText: this.$t('common.month') },
                        timeGridWeek: { buttonText: this.$t('common.week')  },
                        timeGridDay: { buttonText: this.$t('common.day')  },
                    },
                    editable: true,
                    dayMaxEvents: true, // allow "more" link when too many events
                    events: events,
                    locale: trLocale,
                    dateClick: this.dateClick,
                    eventClick: this.eventClick,
                    eventDrop: this.eventDrop,
                    drop: this.drop
                };
                this.showCalendar = true;
            });
        },
        dateClick(event){
            console.log("Date Click", event);
        },
        eventClick(argument){
            this.form.title = this.$t('pages.ecommerce.productManagement.product.lessonCalendarSettings.editLessonCalendar');

            let filtered = this.lessonCalendars.filter((lessonCalendar) => lessonCalendar.id == argument.event.id);

            if(filtered.length == 1){
                let item = filtered[0];

                item.start_date = this.$moment(item.start_date).format('YYYY-MM-DD HH:mm:ss');
                item.end_date = this.$moment(item.end_date).format('YYYY-MM-DD HH:mm:ss');

                this.form.data = item;

                this.showModal(this.$refs.lessonCalendarModal);
            }
        },
        eventDrop(argument){
            this.form.data = {
                id: argument.event.id,
                start_date: this.$moment(argument.event.start).format('YYYY-MM-DD HH:mm:ss'),
                end_date: this.$moment(argument.event.end).format('YYYY-MM-DD HH:mm:ss')
            };

            this.onSubmit();
        },
        drop(event){
            let moment = this.$moment(event.date);

            this.form.data = {
                product_id: this.productID,
                start_date: this.sprintf("%s 12:00:00", [moment.format('YYYY-MM-DD')]),
                end_date: this.sprintf("%s 13:00:00", [moment.format('YYYY-MM-DD')]),
            };

            this.onSubmit();
        },
        onSubmit(){
            this.form.loading = true;

            if(this.form.data.id) {
                this.axios.put(this.endpoints['module_lesson_calendar'] + '/' + this.form.data.id, this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.initializeLessonCalendar();
                        this.hideModal(this.$refs.lessonCalendarModal);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }else {
                this.axios.post(this.endpoints['module_lesson_calendar'], this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.initializeLessonCalendar();
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        deleteRecord(){
            this.$store.dispatch("module/lessonCalendar/delete", {
                id: [this.form.data.id]
            }).then((successDeleted) => {
                if(successDeleted){
                    this.initializeLessonCalendar();
                    this.hideModal(this.$refs.lessonCalendarModal);
                }
            });
        },
        deleteRecordAll(){
            this.$store.dispatch("module/lessonCalendar/deleteAll", {
                id: this.productID
            }).then((success) => {
                if(success){
                    this.initializeLessonCalendar();
                }
            });
        },
        onSubmitRepeatForm(){
            this.$refs.repeatlessonCalendarForm.validate((valid) => {
                if (valid) {
                    let formData = this.cloneData(this.form.repeatFormData);

                    if(formData.start_time >= formData.end_time){
                        this.$notify({
                            type: 'warning',
                            title: this.$t("messages.warning"),
                            message: this.$t("pages.ecommerce.productManagement.product.lessonCalendarSettings.warnings.time"),
                        })

                        return false;
                    }

                    this.form.loading = true;

                    let items = [];
                    let date = this.$moment(formData.date);

                    for(let i = 0; i < formData.repeat_count; i++){
                        items.push({
                            product_id: this.product_id,
                            start_date: this.sprintf("%s %s:00", [date.format('YYYY-MM-DD'), formData.start_time]),
                            end_date: this.sprintf("%s %s:00", [date.format('YYYY-MM-DD'), formData.end_time]),
                        });

                        date.add(1, 'week');
                    }

                    this.axios.post(this.endpoints['module_lesson_calendar_batch'], {
                        product_id: this.productID,
                        teacher_id: formData.teacher_id,
                        items: items
                    }).then(response => {
                        this.onResponse(response.data, () => {
                            this.initializeLessonCalendar();

                            this.form.repeatFormData = {
                                repeat_count: 3
                            };

                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        disabledStartDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.form.data.end_date ? this.$moment(this.form.data.end_date) : undefined;

            return endDate == undefined ? false : startDate.valueOf() > endDate.valueOf()
        },
        disabledEndDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.form.data.start_date ? this.$moment(this.form.data.start_date) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
        onSubmitLessonCalendar(){
            this.$refs.lessonCalendarForm.validate((valid) => {
                if (valid) {
                    this.onSubmit();
                } else {
                    return false;
                }
            });
        }
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>