<template>
    <custom-table
        :title="$t('pages.ecommerce.productManagement.product.variantSettings.title')"
        :subTitle="$t('pages.ecommerce.productManagement.product.variantSettings.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow">
        <template v-slot:variant="{ row: record }">
            {{ getVariant(record.variants, 'item.name').join(' / ') }}
        </template>
        <template v-slot:price="{ row: record }">
            {{ record.prices.length ? resolveDatum(record.prices, $root.defaultCurrency.id, 'currency_id').price + " " + $root.defaultCurrency.suffix : "-" }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchVariant(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_variant" ref="addVariantModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form" ref="variantForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="d-flex justify-content-end" v-if="!form.updateStatus">
                            <button class="btn btn-sm btn-light-primary" v-on:click="form.variants.push({})" type="button">
                                 <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
                                     <inline-svg src="/media/icons/duotune/arrows/arr075.svg"/>
                                 </span>
                                {{ $t("pages.ecommerce.productManagement.product.variantSettings.addNewVariant") }}
                            </button>
                        </div>
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                             data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <template v-if="!form.updateStatus">
                                <div v-for="(variant, variantIndex) in form.variants" :key="variantIndex">
                                    <el-divider content-position="center" class="mb-10">
                                        {{ sprintf($t("pages.ecommerce.productManagement.product.variantSettings.variantPattern"), [(variantIndex + 1)]) }}
                                        <button class="btn btn-sm btn-light-primary ms-3" v-on:click="form.variants.splice(variantIndex, 1)" type="button" v-if="variantIndex != 0">
                                            <span class="svg-icon svg-icon-5 svg-icon-ray-500 me-1">
                                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                            </span>
                                        </button>
                                    </el-divider>

                                    <div class="row">
                                        <div class="col-md-6 fv-row mb-1">
                                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.variantSettings.cols.variantGroup') }}</label>
                                            <el-form-item :prop="'variants['+variantIndex+'].variantGroupIndex'" :rules="$validation.getMessage(['required'])">
                                                <el-select v-model="form.variants[variantIndex].variantGroupIndex" class="w-100" :placeholder="$t('common.chooseSelect')">
                                                    <el-option v-for="(variantGroup, variantGroupIndex) in variantGroups" :key="variantGroupIndex" :value="variantGroupIndex" :label="variantGroup.name">{{ variantGroup.name }}</el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>

                                        <div class="col-md-6 fv-row mb-1" v-if="form.variants[variantIndex].variantGroupIndex !== undefined">
                                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.variantSettings.cols.variantItems') }}</label>
                                            <el-form-item :prop="'variants['+variantIndex+'].variantItemID'" :rules="$validation.getMessage(['required'])">
                                                <el-select v-model="form.variants[variantIndex].variantItemID" class="w-100" :placeholder="$t('common.chooseSelect')">
                                                    <el-option v-for="(variantItem, variantItemIndex) in variantGroups[form.variants[variantIndex].variantGroupIndex].items" :key="variantItemIndex" :value="variantItem.id" :label="variantItem.name">
                                                        {{ variantItem.name }}
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.variantSettings.cols.stock') }}</label>
                                <el-form-item prop="data.stock" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.stock" :min="1"/>
                                </el-form-item>
                            </div>

                            <div class="row">
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">
                                        {{ $t('pages.ecommerce.productManagement.product.variantSettings.cols.price') }}
                                        <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.productManagement.product.variantSettings.informationBoxes.price')" placement="top">
                                            <span class="svg-icon svg-icon-1">
                                                <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                            </span>
                                        </el-tooltip>
                                    </label>
                                    <el-form-item prop="data.prices[0].price">
                                        <el-input v-model="form.data.prices[0].price" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                    </el-form-item>
                                </div>
                                <div class="col-md-6 fv-row mb-1" v-if="form.data.prices[0].price && form.data.prices[0].price.length">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.variantSettings.cols.discountPrice') }}</label>
                                    <el-form-item prop="data.prices[0].discount_price">
                                        <el-input v-model="form.data.prices[0].discount_price" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "Variant",
    props: ['productID', 'productGroupID'],
    inject: ['loadCustomizePage'],
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.productManagement.product.variantSettings.cols.id"),
                    key: "id"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.variantSettings.cols.variant"),
                    scopedSlots: {customRender: "variant"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.variantSettings.cols.stock"),
                    key: "stock"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.variantSettings.cols.price"),
                    scopedSlots: {customRender: "price"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                variants: [],
                data: {
                    stock: 1,
                    prices: [{currency_id: this.$root.defaultCurrency.id}],
                }
            },
            selectedRowKeys: [],
            table: {
                data: [],
                loading: false
            }
        }
    },
    computed: {
        productGroup() {
            return this.$store.state.ecommerce.productManagement.product.group.table.data;
        },
        variantGroups() {
            let variantGroup = [];

            if (this.productGroup[0] && this.productGroup[0].supported_variant_group.length) {
                variantGroup = this.productGroup[0].supported_variant_group;
            }

            return variantGroup;
        }
    },
    mounted() {
        this.loadProduct();

        this.$store.dispatch('ecommerce/productManagement/product/group/get', {
            filterData: {id: this.productGroupID}
        });
    },
    methods: {
        loadProduct() {
            this.table.loading = true;

            this.axios.get(this.endpoints['ecommerce_product'] + '/' + this.productID).then((response) => {
                let data = response.data.data;
                this.loadCustomizePage(data.customize_page_name);
                this.table.data = data.variant_combinations;
            }).finally(() => {
                this.table.loading = false;
            });
        },
        newVariant() {
            this.form.updateStatus = false;
            this.form.variants = [{}];
            this.form.data = {
                product_id: this.productID,
                prices: [{currency_id: this.$root.defaultCurrency.id}],
                stock: 1
            };
            this.form.title = this.$t("pages.ecommerce.productManagement.product.variantSettings.newVariant");
            this.showModal(this.$refs.addVariantModal);
        },
        fetchVariant(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.ecommerce.productManagement.product.variantSettings.editVariant");
            this.axios.get(this.endpoints['ecommerce_product_variant']+ '/' + record.id).then(response => {
                let data = response.data.data;

                this.form.data = {
                    id: data.id,
                    stock: data.stock,
                    prices: data.prices.length ? data.prices : [{currency_id: this.$root.defaultCurrency.id}],
                };

                this.showModal(this.$refs.addVariantModal);
            });
        },
        onSubmit() {
            this.$refs.variantForm.validate((valid) => {
                if (valid) {
                    let formData = this.prepareVariantFormData();
                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['ecommerce_product_variant'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadProduct();
                                this.hideModal(this.$refs.addVariantModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        let items = [];

                        for(let i = 0; i < this.form.variants.length; i++) {
                            items.push(this.form.variants[i].variantItemID);
                        }

                        formData.items = items;

                        this.axios.post(this.endpoints['ecommerce_product_variant'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadProduct();
                                this.hideModal(this.$refs.addVariantModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            if(error.response.data.message == 'combination_already_exists') {
                                error.response.data.message = this.$t('pages.ecommerce.productManagement.product.variantSettings.responseMessages.combinationAlreadyExists');
                            }

                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("ecommerce/productManagement/product/variant/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if(successDeleted) {
                    this.loadProduct();
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadProduct();
                    break;

                case "new":
                    this.newVariant();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        getVariant(variants, column){
            let data = [];

            variants.forEach((variant) => {
                data.push(this.objectPath.get(variant, column));
            })

            return data;
        },
        prepareVariantFormData(){
            let formData = JSON.parse(JSON.stringify(this.form.data));

            if(!(formData.prices[0].price && formData.prices[0].price.length)){
                formData.prices = [];
            }

            return formData;
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
    }
}
</script>

<style>

</style>