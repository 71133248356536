<template>
    <div class="card" v-if="load.state">
        <div class="card-body pt-9" :class="customBodyClass">
            <el-form :model="form.data" ref="productRelationForm">
                <div v-if="product.variant_combinations_count > 0">
                    <h3 class="fw-bolder text-center mb-10">{{ $t('pages.ecommerce.invoiceManagement.provider.product.variantCombination.title') }}</h3>

                    <div class="fv-row mb-7" v-for="(combinationRelation, combinationRelationIndex) in form.variantCombinations" :key="combinationRelationIndex">
                        <div class="separator separator-dashed mb-7" v-if="combinationRelationIndex != 0"></div>

                        <label class="fs-6 fw-bold mb-2">{{ sprintf('%d. %s', [(combinationRelationIndex + 1), getVariant(combinationRelation.variants, 'item.name').join(' / ')]) }}</label>

                        <el-form-item class="custom-form-item mb-2">
                            <el-select v-model="combinationRelation.provider_product_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable>
                                <el-option v-for="(providerProduct, providerProductIndex) in providerProducts" :key="providerProductIndex" :value="providerProduct.id" :label="providerProduct.name"></el-option>
                            </el-select>
                            <div class="d-flex">
                                <a v-on:click="onSubmit(combinationRelation)" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-icon btn-primary btn-sm me-1 ms-1" :class="{'pe-none': form.loading}">
                                    <span class="svg-icon svg-icon-3" v-if="!form.loading">
                                        <inline-svg src="/media/icons/duotune/arrows/arr012.svg"/>
                                    </span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        <span class="spinner-border spinner-border-sm align-middle"></span>
                                    </span>
                                </a>
                                <el-popconfirm v-if="combinationRelation.id" :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord(combinationRelation)">
                                    <template #reference>
                                        <a class="btn btn-icon btn-danger btn-sm cursor-pointer">
                                            <span class="svg-icon svg-icon-3">
                                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                            </span>
                                        </a>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </el-form-item>
                        <el-popconfirm :title="$t('messages.sureAction')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="createProduct(combinationRelation.product_id, combinationRelation.variant_combination_id, combinationRelationIndex)">
                            <template #reference>
                                <a target="_blank" class="fw-bold text-gray-600 text-hover-primary cursor-pointer">
                                    {{ $t('pages.ecommerce.invoiceManagement.provider.product.createProduct.variant') }}
                                </a>
                            </template>
                        </el-popconfirm>
                    </div>
                </div>
                <div v-else>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.invoiceManagement.provider.product.cols.providerProductTitle') }}</label>
                        <el-form-item class="custom-form-item mb-2">
                            <el-select v-model="form.data.provider_product_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable>
                                <el-option v-for="(providerProduct, providerProductIndex) in providerProducts" :key="providerProductIndex" :value="providerProduct.id" :label="providerProduct.name"></el-option>
                            </el-select>
                            <div class="d-flex" v-if="form.data.id">
                                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord(form.data)">
                                    <template #reference>
                                        <a class="btn btn-icon btn-danger btn-sm ms-1">
                                            <span class="svg-icon svg-icon-3">
                                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                            </span>
                                        </a>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </el-form-item>
                        <el-popconfirm :title="$t('messages.sureAction')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="createProduct(form.data.product_id)">
                            <template #reference>
                                <a target="_blank" class="fw-bold text-gray-600 text-hover-primary cursor-pointer">
                                    {{ $t('pages.ecommerce.invoiceManagement.provider.product.createProduct.product') }}
                                </a>
                            </template>
                        </el-popconfirm>
                    </div>
                    <div class="fv-row mt-5 text-center">
                        <el-form-item class="mb-0">
                            <button @click.prevent="onSubmit(form.data)" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button">
                                <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                <span v-if="form.loading" class="indicator-progress">
                                    {{ $t("messages.wait") }}
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    props: {
        productID: {type: Number},
        providerID: {type: Number, default: 0},
        autoLoad: {type: Boolean, default: false},
        customBodyClass: {
            type: Object, default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            providerProducts: [],
            product: {},
            productRelation: {},
            form: {
                loading: false,
                variantCombinations: [],
                data: {}
            },
            load: {
                options: {},
                state: false
            }
        }
    },
    mounted() {
        if (this.autoLoad) {
            this.loadAllData();
        }
    },
    methods: {
        async loadAllData(options = {}) {
            this.load = {options: options, state: false};

            try {
                if (!this.providerProducts.length) {
                    let providerProductsResult = await this.loadProviderProducts();
                    if (!providerProductsResult.status) {
                        throw providerProductsResult.message;
                    }
                }

                let productResult = await this.loadProduct();
                if (!productResult.status) {
                    throw productResult.message;
                }

                let productRelation = await this.loadProductRelation();
                if (!productRelation.status) {
                    throw productRelation.message;
                }

                this.load.state = true;

                return {status: true};
            } catch (error) {
                this.$swal.fire({
                    title: this.$t("messages.error"),
                    text: this.$t('pages.ecommerce.invoiceManagement.provider.product.errors.' + error, error),
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: this.$t('btn.ok'),
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });

                return {status: false, message: error};
            }
        },
        async loadProviderProducts() {
            try {
                await this.axios.get(this.endpoints['ecommerce_invoice_provider_util_product_list'] + '/' + this.providerID).then(response => {
                    let data = response.data.data;
                    if (!response.data.status) {
                        throw 'could_not_fetch_provider_product'
                    }

                    this.providerProducts = data;
                }).catch(error => {
                    throw error.response ? error.response.data : error;
                });

                return {status: true, message: "success_fetch_provider_product"};
            } catch (error) {
                return {status: false, message: 'could_not_fetch_provider_product', error: error};
            }
        },
        async loadProduct() {
            try {
                await this.axios.get(this.endpoints['ecommerce_product'] + '/' + (this.productID ?? this.load.options.product_id)).then(response => {
                    let data = response.data.data;
                    if (!response.data.status) {
                        throw 'could_not_fetch_product'
                    }

                    this.product = data;
                }).catch(error => {
                    throw error.response ? error.response.data : error;
                });

                return {status: true, message: "success_fetch_product"};
            } catch (error) {
                return {status: false, message: 'could_not_fetch_product', error: error};
            }
        },
        async loadProductRelation() {
            try {
                await this.axios.get(this.endpoints['ecommerce_invoice_provider_product'], {
                    params: {
                        provider_id: this.providerID,
                        product_id: (this.productID ?? this.load.options.product_id)
                    }
                }).then(response => {
                    let data = response.data.data;
                    if (!response.data.status) {
                        throw 'could_not_fetch_product_relation'
                    }

                    let productRelation = {};
                    data.forEach((relation) => {
                        productRelation[this.sprintf('%d_%d', [relation.product_id, relation.variant_combination_id ?? 0])] = relation;
                    });

                    this.productRelation = productRelation;

                    this.prepareFormData();
                }).catch(error => {
                    throw error.response ? error.response.data : error;
                });

                return {status: true, message: "success_fetch_product_relation"};
            } catch (error) {
                return {status: false, message: 'could_not_fetch_product_relation', error: error};
            }
        },
        onSubmit(formData) {
            if (!(formData.provider_product_id && formData.provider_product_id.trim().length)) {
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.ecommerce.invoiceManagement.provider.product.warnings.requiredProviderProduct"),
                })

                return false;
            }

            formData.provider_id = this.providerID;

            this.form.loading = true
            if (formData.id) {
                this.axios.put(this.endpoints['ecommerce_invoice_provider_product'] + '/' + formData.id, formData).then(response => {
                    this.onResponse(response.data, () => {
                        this.successEvent();
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data);
                }).finally(() => {
                    this.form.loading = false
                });
            } else {
                this.axios.post(this.endpoints['ecommerce_invoice_provider_product'], formData).then(response => {
                    this.onResponse(response.data, () => {
                        this.successEvent();
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data);
                }).finally(() => {
                    this.form.loading = false;
                });
            }
        },
        convertRelationDataToFormData(product_id, variant_combination_id, variants = []) {
            let relationKey = this.sprintf('%d_%d', [product_id, (variant_combination_id ?? 0)]);
            let relationData = this.cloneData(this.productRelation[relationKey] ?? {});

            return {
                id: relationData.id,
                product_id: product_id,
                variant_combination_id: variant_combination_id,
                provider_product_id: relationData.provider_product_id,
                variants: variants
            };
        },
        prepareFormData() {
            let formData = {};
            let variantCombinations = [];

            if (this.product.variant_combinations_count > 0) {
                this.product.variant_combinations.forEach((combination) => {
                    variantCombinations.push(this.convertRelationDataToFormData(combination.product_id, combination.id, combination.variants));
                });
            } else {
                formData = this.convertRelationDataToFormData(this.product.id, null);
            }

            this.form.variantCombinations = variantCombinations;
            this.form.data = formData;
        },
        getVariant(variants, column) {
            let data = [];

            variants.forEach((variant) => {
                data.push(this.objectPath.get(variant, column));
            })

            return data;
        },
        successEvent() {
            this.loadProductRelation();
            this.$emit("successRequest");
        },
        deleteRecord(record) {
            this.axios.delete(this.endpoints['ecommerce_invoice_provider_product'] + '/' + record.id).then((response) => {
                if (response.data.status) {
                    this.$message.success(this.$t('messages.deleteOk'));
                    this.successEvent()
                } else {
                    this.$message.error(response.data.message)
                }
            }).catch((error) => {
                this.$message.error(error.response.data.message)
            })
        },
        createProduct(productID, variantCombinationID = null, formVariantCombinationIndex = undefined) {
            this.form.loading = false;

            this.axios.post(this.endpoints['ecommerce_invoice_provider_product_create_product'], {
                provider_id: this.providerID,
                product_id: productID,
                variant_combination_id: variantCombinationID
            }).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;

                    this.loadProviderProducts().then(() => {
                        if(variantCombinationID) {
                            this.form.variantCombinations[formVariantCombinationIndex].provider_product_id = data.id;
                        } else {
                            this.form.data.provider_product_id = data.id;
                        }
                    });
                });
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            }).finally(() => {
                this.form.loading = false;
            });
        }
    }
}
</script>


<style>
.custom-form-item .el-form-item__content {
    display: flex;
    align-items: center;
}
</style>