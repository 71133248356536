<template>
    <custom-table
        :title="$t('pages.module.document.title')"
        :subTitle="$t('pages.module.document.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :pagination="table.pagination"
        :columns="fields"
        :actions="actions"
        :cardConfig="documentableType == 'LECTURE' && {removePadding: true}"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.module.document.previewDocument')" placement="top">
                    <a :href="record.upload.public_url" target="_blank" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                        </span>
                    </a>
                </el-tooltip>
                <a v-on:click="fetchDocument(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_document" ref="addDocumentModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="documentForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7" v-if="!form.updateStatus">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.document.cols.type') }}</label>
                                <el-form-item prop="type" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.type" class="w-100" :placeholder="$t('common.chooseSelect')" @change="selectedType">
                                        <el-option value="SINGLE" :label="$t('pages.module.document.types.single')"></el-option>
                                        <el-option value="MULTI" :label="$t('pages.module.document.types.multi')"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <template v-if="form.data.type || form.updateStatus">
                                <div class="fv-row mb-7" v-if="form.data.type != 'MULTI'">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.document.cols.name') }}</label>
                                    <el-form-item prop="name" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.data.name" type="text"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-7" v-if="!form.updateStatus">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.document.upload.title') }}</label>
                                    <el-form-item prop="upload_id">
                                        <el-upload :file-list="form.file.list" :accept="acceptFiles" class="upload-file" :on-change="handleChangeFile" :on-remove="handleChangeFile" :auto-upload="false" drag :multiple="(form.data.type == 'MULTI' ? true : false)" :limit="(form.data.type == 'MULTI' ? 10 : 1)">
                                            <el-icon class="el-icon--upload">
                                             <span class="svg-icon">
                                                 <inline-svg src="/media/icons/duotune/files/fil022.svg" />
                                             </span>
                                            </el-icon>
                                            <div class="el-upload__text">{{ $t('pages.module.document.upload.dragFile')}}</div>
                                        </el-upload>
                                    </el-form-item>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    props: ['documentableType', 'documentableID'],
    name: "Document",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.module.document.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.document.cols.name"),
                    key: "name"
                },
                {
                    name: this.$t("pages.module.document.cols.documentName"),
                    key: "upload.name"
                },
                {
                    name: this.$t("pages.module.document.cols.documentExtension"),
                    key: "upload.extension"
                },
                {
                    name: this.$t("pages.module.document.cols.documentSize"),
                    key: "upload.size_text"
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                file: {
                    list: [],
                    uploadList: []
                },
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: [],
        }
    },
    computed: {
        table() {
            return this.$store.state.module.document.table;
        },
        acceptFiles(){
            let accepts = [];
            ['document', 'compressed'].forEach((group) => {
                let files = this.$root.acceptFileSupported(group, '.');

                if(files) {
                    accepts.push(files);
                }
            })

            return accepts.join(', ');
        }
    },
    mounted() {
        this.$store.dispatch('module/document/get', {
            page: {},
            filterData: {
                documentable_type: this.documentableType,
                documentable_id: this.documentableID
            }
        });
    },
    methods: {
        newDocument() {
            this.form.updateStatus = false;
            this.form.file = {
                list: [],
                uploadList: []
            };

            this.form.data = {
                documentables: {},
                type: 'SINGLE'
            };
            this.form.data.documentables[this.documentableType] = [this.documentableID];

            this.form.title = this.$t("pages.module.document.newDocument");
            this.showModal(this.$refs.addDocumentModal);
        },
        fetchDocument(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.module.document.editDocument");
            this.axios.get(this.endpoints['module_document'] + '/' + record.id).then(response => {
                let data = response.data.data;

                this.form.data = data;
                this.showModal(this.$refs.addDocumentModal);
            });
        },
        onSubmit() {
            this.$refs.documentForm.validate((valid) => {
                if (valid) {
                    if(this.form.updateStatus) {
                        this.submitForm();
                    } else {
                        this.submitFormWithUpload();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            if(!(this.form.file.uploadList.length > 0)){
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.module.document.warnings.requiredDocument"),
                })
                return false;
            }

            this.form.loading = true;

            const formData = new FormData();

            this.form.file.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    if(this.form.data.type == 'SINGLE') {
                        this.form.data.upload_id = data[0].id;
                        this.submitForm();
                    } else if(this.form.data.type == 'MULTI'){
                        this.form.data.upload_id = data;
                        this.submitFormMulti();
                    }
                }, () => {}, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm() {
            this.form.loading = true;

            if (this.form.data.id) {
                this.axios.put(this.endpoints['module_document'] + '/' + this.form.data.id, this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.refreshTable();
                        this.updateDraggableLecture();
                        this.hideModal(this.$refs.addDocumentModal);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            } else {
                this.axios.post(this.endpoints['module_document'], this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.refreshTable();
                        this.updateDraggableLecture();
                        this.hideModal(this.$refs.addDocumentModal);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        submitFormMulti(){
            this.form.loading = true;

            this.multiRequest().then((response) => {
                this.onResponse(response, () => {
                    this.refreshTable();
                    this.updateDraggableLecture();
                    this.hideModal(this.$refs.addDocumentModal);
                }, () => {
                    this.form.loading = false;
                });
            }).finally(() => {
                this.form.loading = false;
            })
        },
        async multiRequest() {
            return new Promise((resolve, reject) => {
                let multiRequest = [];
                let formData = this.cloneData(this.form.data);

                this.form.data.upload_id.forEach((upload) => {
                    formData.upload_id = upload.id;
                    formData.name = upload.name;
                    multiRequest.push(this.axios.post(this.endpoints['module_document'], formData));
                })

                this.axios.all(multiRequest).then(this.axios.spread((...responses) => {
                    let result = [];

                    for (let i = 0; i < responses.length; i++) {
                        let handleResponse = responses[i].data;

                        if (!handleResponse.status) {
                            break;
                        }

                        result.push(handleResponse.data);
                    }

                    if (result.length == responses.length) {
                        resolve({status: true, data: result});
                    } else {
                        reject('request_count_dont_match_with_response_count');
                    }
                })).catch((errors) => {
                    reject('occurred_any_error');
                })
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("module/document/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));

                if(successDeleted){
                    this.updateDraggableLecture();
                }
            });
        },
        refreshTable(){
            this.$store.dispatch("module/document/refresh");
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newDocument();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/document/get", {
                page: pagination,
                filterData: Object.assign({
                    documentable_type: this.documentableType,
                    documentable_id: this.documentableID
                }, filterData)
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        handleChangeFile(file, fileList){
            this.form.file.list = fileList;
            this.form.file.uploadList = fileList;
        },
        updateDraggableLecture(){
            if(this.documentableType == 'LECTURE'){
                this.eventBus.$emit('updateDraggableLecture', this.documentableID);
            }
        },
        selectedType(){
            this.form.file = {
                list: [],
                uploadList: []
            };
        }
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.upload-file .el-upload {
    width: 100%;
}

.upload-file .el-upload .el-upload-dragger{
    width: 100%;
}
.svg-icon svg {
    height: 1em;
    width: 1em;
}
</style>