<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            <template v-if="productID">
                {{ sprintf($t('pages.ecommerce.productManagement.product.save.editProduct'), [productTranslate.title ?? '']) }}
                <a class="btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 ms-1" :href="$root.appUrl + '/' + productTranslate.slug" target="_blank">
                    <span class="svg-icon svg-icon-1">
                        <inline-svg src="/media/icons/duotune/coding/cod007.svg"/>
                    </span>
                </a>
            </template>
            <template v-else>{{ $t('pages.ecommerce.productManagement.product.save.newProduct') }}</template>
        </h2>

        <router-link v-if="!$root.filterWithUrl" to="/ecommerce/product-management/product" class="btn btn-primary align-self-center" >
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.ecommerce.productManagement.product.title") }}
        </router-link>
    </div>
    <el-tabs v-model="tabModel" class="product-tabs">
        <el-tab-pane :label="$t('pages.ecommerce.productManagement.product.save.tabs.generalDetails')" name="general">
            <el-form :model="form" ref="productForm">
                <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.title') }}</label>
                                    <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.translate.title" type="text"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.description') }}</label>
                                    <el-form-item prop="translate.description">
                                        <CustomCkeditor :model="form.translate.description" @updateModel="form.translate.description = $event"></CustomCkeditor>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.content') }}</label>
                                    <el-form-item prop="translate.content" :rules="$validation.getMessage(['required'])">
                                        <CustomCkeditor :model="form.translate.content" @updateModel="form.translate.content = $event"></CustomCkeditor>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-7">
                            <div class="card-header pt-4 pb-4">
                                <h3 class="card-title align-items-start flex-column">
                                    {{ $t('pages.ecommerce.productManagement.product.save.seoSettings') }}
                                </h3>
                            </div>
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.seoCols.slug') }}</label>
                                    <el-form-item prop="translate.meta.slug">
                                        <el-input v-model="form.translate.slug" type="text"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.seoCols.title') }}</label>
                                    <el-form-item prop="translate.meta.title">
                                        <el-input v-model="form.translate.meta.title" type="text"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.seoCols.description') }}</label>
                                    <el-form-item prop="translate.meta.description">
                                        <el-input v-model="form.translate.meta.description" type="textarea" rows="3"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.seoCols.keywords') }}</label>
                                    <el-form-item prop="translate.meta.keyword">
                                        <el-input v-model="form.translate.meta.keyword" type="textarea" rows="3"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.seoCols.tags') }}</label>
                                    <el-form-item prop="translate.tags">
                                        <el-tag v-for="tag in form.translate.tags" :key="tag" closable :disable-transitions="false" @close="handleTagClose(tag)">
                                            {{ tag }}
                                        </el-tag>
                                        <el-input v-if="tags.inputVisible" ref="saveTagInput" v-model="tags.inputValue" class="input-new-tag" size="mini" @keyup.enter="handleTagInputConfirm"></el-input>
                                        <el-button v-else class="button-new-tag" size="small" @click="showTagInput">+ {{ $t('pages.ecommerce.productManagement.product.save.newTag') }}</el-button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-7" v-if="supportedOptionGroups.length">
                            <div class="card-header pt-4 pb-4">
                                <h3 class="card-title align-items-start flex-column">
                                    {{ $t('pages.ecommerce.productManagement.product.groupSettings.supportOptions.title') }}
                                </h3>
                            </div>
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1" v-for="(optionItem, optionItemKey) in supportedOptionGroups" :key="optionItemKey" v-init="form.supportedOptions[optionItem.id] = getOptionModelValue('options', optionItem.options, undefined)">
                                    <label class="fs-6 fw-bold mb-2">{{ optionItem.name }}</label>
                                    <el-form-item prop="data.type_id">
                                        <el-select v-model="form.supportedOptions[optionItem.id]" clearable @clear="form.supportedOptions[optionItem.id] = undefined" class="w-100" :placeholder="$t('common.chooseSelect')">
                                            <el-option v-for="(option, optionIndex) in optionItem.options" :key="optionIndex" :value="option.id" :label="option.name">{{ option.name }}</el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-7" v-if="supportedDetailGroups.length">
                            <div class="card-header pt-4 pb-4">
                                <h3 class="card-title align-items-start flex-column">
                                    {{ $t('pages.ecommerce.productManagement.product.groupSettings.supportDetails.title') }}
                                </h3>
                            </div>
                            <div class="card-body d-flex flex-column p-9">
                                <div v-for="(detailItem, detailItemKey) in supportedDetailGroups" :key="detailItemKey">
                                    <el-divider content-position="center">{{ detailItem.name }}</el-divider>
                                    <div class="fv-row mb-1" v-for="(item, itemIndex) in detailItem.items" :key="itemIndex" v-bind:class="{ 'mt-10': itemIndex == 0 }" v-init="form.supportedDetails[item.id] = getOptionModelValue('details', [item], {})">
                                        <label class="fs-6 fw-bold mb-2">{{ item.name }}</label>
                                        <el-form-item prop="data.type_id">
                                            <el-input type="hidden" v-model="form.supportedDetails[item.id].item_id" v-init="form.supportedDetails[item.id].item_id = item.id"/>
                                            <el-input type="text" v-model="form.supportedDetails[item.id].val"/>
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-7">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.image') }}</label>
                                    <el-form-item prop="data.image_id">
                                        <el-upload :accept="$root.acceptFileSupported('image', 'image/')" v-bind:class="{'disabled-upload': image.fileList.length > 0}" :on-change="handleChangeImage" :on-remove="handleChangeImage"
                                                   list-type="picture-card" :file-list="image.fileList" limit="1" :on-preview="handlePictureCardPreview" :auto-upload="false">
                                            <i class="bi bi-plus"/>
                                        </el-upload>
                                        <el-dialog v-model="image.dialogVisible" width="30%">
                                            <img style="width: 100%" :src="image.dialogImageUrl" alt=""/>
                                        </el-dialog>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.categories') }}</label>
                                    <el-form-item prop="data.categories">
                                        <el-input type="hidden" v-model="form.data.categories"></el-input>
                                        <el-select-tree :value="form.data.categories" @input="form.data.categories = $event" :data="treeCategories" :placeholder="$t('common.chooseSelect')"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.type') }}</label>
                                    <el-form-item prop="data.type_id" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.data.type_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                            <el-option v-for="(type, typeIndex) in productTypes" :key="typeIndex" :value="type.id" :label="type.name">{{ type.name }}</el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.group') }}</label>
                                    <el-form-item prop="data.group_id" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.data.group_id" class="w-100" :placeholder="$t('common.chooseSelect')" :disabled="form.updateStatus">
                                            <el-option v-for="(group, groupIndex) in productGroups" :key="groupIndex" :value="group.id" :label="group.name">{{ group.name }}</el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1" v-if="['PACKAGE', 'LESSON'].includes(selectedGroup.code)">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.teachers') }}</label>
                                    <el-form-item prop="data.teachers">
                                        <el-select v-model="form.data.teachers" class="w-100" multiple :placeholder="$t('common.chooseSelect')">
                                            <el-option v-for="(teacher, teacherIndex) in teachers" :key="teacherIndex" :value="teacher.id" :label="teacher.firstname + ' ' + teacher.lastname">{{ teacher.firstname + ' ' + teacher.lastname }}
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1" v-if="['PACKAGE', 'LESSON'].includes(selectedGroup.code)">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.examDate') }}</label>
                                    <el-form-item prop="data.exam_date_id">
                                        <el-select v-model="form.data.exam_date_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                            <el-option v-for="(examDate, examDateIndex) in examDates" :key="examDateIndex" :value="examDate.id" :label="examDate.name + ' - ' + examDate.date">{{ examDate.name + ' - ' + examDate.date }}</el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1" v-if="selectedGroup.code == 'LESSON'">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.lessonType') }}</label>
                                    <el-form-item prop="data.lesson_type_id">
                                        <el-select v-model="form.data.lesson_type_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                            <el-option v-for="(lessonType, lessonTypeIndex) in lessonTypes" :key="lessonTypeIndex" :value="lessonType.id" :label="lessonType.name">{{ lessonType.name }}</el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.taxes') }}</label>
                                    <el-form-item prop="data.taxes">
                                        <el-select v-model="form.data.taxes" class="w-100" multiple :placeholder="$t('common.chooseSelect')">
                                            <el-option v-for="(tax, taxIndex) in taxes" :key="taxIndex" :value="tax.id" :label="tax.name + ' (' + tax.rate + ')'"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.price') }}</label>
                                        <el-form-item prop="data.prices[0].price" :rules="$validation.getMessage(['required'])">
                                            <el-input v-model="form.data.prices[0].price" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-6 fv-row mb-1">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.discountPrice') }}</label>
                                        <el-form-item prop="data.prices[0].discount_price">
                                            <el-input v-model="form.data.prices[0].discount_price" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.saleMinDate') }}</label>
                                    <el-form-item prop="data.sale_min_date">
                                        <el-date-picker
                                            class="rangeDateTimePicker"
                                            v-model="form.data.sale_min_date"
                                            type="datetime"
                                            popper-class="rangeDateTimePickerPopper"
                                            :placeholder="$t('common.chooseDateAndTime')"
                                            valueFormat="YYYY-MM-DD HH:mm:ss"
                                            :disabledDate="disabledSaleMinDate"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.saleMaxDate') }}</label>
                                    <el-form-item prop="data.sale_max_date">
                                        <el-date-picker
                                            class="rangeDateTimePicker"
                                            v-model="form.data.sale_max_date"
                                            type="datetime"
                                            popper-class="rangeDateTimePickerPopper"
                                            :placeholder="$t('common.chooseDateAndTime')"
                                            valueFormat="YYYY-MM-DD HH:mm:ss"
                                            :disabledDate="disabledSaleMaxDate"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </div>

                                <template v-if="['PACKAGE', 'LESSON'].includes(selectedGroup.code) && form.data.type_id == 2">
                                    <div class="fv-row mb-1" v-if="form.data.usage_type !== 2">
                                        <label class="fs-6 fw-bold mb-2">
                                            {{ $t('pages.ecommerce.productManagement.product.save.cols.usageType') }}

                                            <el-tooltip class="item" effect="dark" popper-class="max-w-300px" :content="$t('pages.ecommerce.productManagement.product.save.informationBoxes.usageType')" placement="top">
                                                <span class="svg-icon svg-icon-1">
                                                    <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                                </span>
                                            </el-tooltip>
                                        </label>

                                        <el-form-item prop="data.usage_type">
                                            <el-select v-model="form.data.usage_type" class="w-100" :placeholder="$t('common.chooseSelect')">
                                                <el-option v-for="(usageType, usageTypeIndex) in usageTypes" :key="usageTypeIndex" :value="usageType" :label="$t('pages.ecommerce.productManagement.product.save.usageType.' + usageTypeIndex)"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>

                                    <template v-if="form.data.usage_type === 1">
                                        <div class="fv-row mb-1">
                                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.usageMinDate') }}</label>
                                            <el-form-item prop="data.usage_min_date">
                                                <el-date-picker
                                                    class="rangeDateTimePicker"
                                                    v-model="form.data.usage_min_date"
                                                    type="datetime"
                                                    popper-class="rangeDateTimePickerPopper"
                                                    :placeholder="$t('common.chooseDateAndTime')"
                                                    valueFormat="YYYY-MM-DD HH:mm:ss"
                                                    :disabledDate="disabledUsageMinDate"
                                                >
                                                </el-date-picker>
                                            </el-form-item>
                                        </div>
                                        <div class="fv-row mb-1">
                                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.usageMaxDate') }}</label>
                                            <el-form-item prop="data.usage_max_date">
                                                <el-date-picker
                                                    class="rangeDateTimePicker"
                                                    v-model="form.data.usage_max_date"
                                                    type="datetime"
                                                    popper-class="rangeDateTimePickerPopper"
                                                    :placeholder="$t('common.chooseDateAndTime')"
                                                    valueFormat="YYYY-MM-DD HH:mm:ss"
                                                    :disabledDate="disabledUsageMaxDate"
                                                >
                                                </el-date-picker>
                                            </el-form-item>
                                        </div>
                                    </template>

                                    <template v-if="form.data.usage_type === 2">
                                        <div class="row">
                                            <div class="col-6 fv-row mb-1">
                                                <label class="fs-6 fw-bold mb-2">
                                                    {{ $t('pages.ecommerce.productManagement.product.save.cols.usageType') }}

                                                    <el-tooltip class="item" effect="dark" popper-class="max-w-300px" :content="$t('pages.ecommerce.productManagement.product.save.informationBoxes.usageType')" placement="top">
                                                        <span class="svg-icon svg-icon-1">
                                                            <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                                        </span>
                                                    </el-tooltip>
                                                </label>

                                                <el-form-item prop="data.usage_type">
                                                    <el-select v-model="form.data.usage_type" class="w-100" :placeholder="$t('common.chooseSelect')">
                                                        <el-option v-for="(usageType, usageTypeIndex) in usageTypes" :key="usageTypeIndex" :value="usageType" :label="$t('pages.ecommerce.productManagement.product.save.usageType.' + usageTypeIndex)"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>

                                            <div class="col-6 fv-row mb-1">
                                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.usageMaxDate') }}</label>
                                                <el-form-item prop="data.usage_max_day">
                                                    <el-input v-model="form.data.usage_max_day" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                                </el-form-item>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">
                                        {{ $t('pages.ecommerce.productManagement.product.save.cols.shipping') }}
                                    </label>
                                    <el-form-item prop="shipping" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.shipping" class="w-100" :placeholder="$t('common.chooseSelect')" @change="form.data.shipping = shipping[form.shipping]">
                                            <el-option v-for="(shipping, shippingKey, shippingIndex) in shipping" :key="shippingIndex" :value="shippingKey" :label="$t('pages.ecommerce.productManagement.product.save.shipping.' + shippingKey)"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1" v-if="form.shipping == 'customPrice'">
                                    <label class="fs-6 fw-bold mb-2">
                                        {{ $t('pages.ecommerce.productManagement.product.save.cols.shippingPrice') }}
                                        <!-- <el-tooltip class="item" effect="dark" :content="$t('pages.ecommerce.productManagement.product.save.informationBoxes.shipping')" placement="top">
                                            <span class="svg-icon svg-icon-1">
                                                <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                            </span>
                                        </el-tooltip>-->
                                    </label>
                                    <el-form-item prop="data.shipping">
                                        <el-input v-model="form.data.shipping" v-mask="{ alias: 'decimal', allowMinus:false, placeholder: '', rightAlign: false }" type="text"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1" v-if="form.data.shipping != -1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.shippingWeight') }}</label>
                                    <el-form-item prop="data.shipping_weight">
                                        <el-input v-model="form.data.shipping_weight" v-mask="{ alias: 'decimal', allowMinus:false, placeholder: '', rightAlign: false, max: 20 }" type="text"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.cols.stockCode') }}</label>
                                    <el-form-item prop="data.stock_code">
                                        <el-autocomplete v-if="stockCodeAutoComplete" value-key="code" name-key="name" v-model="form.data.stock_code" :trigger-on-focus="false" :fetch-suggestions="remoteStockCode" class="w-100">
                                            <template #default="{ item }">
                                                <div class="value">{{ sprintf('%s (%s)', [item.name, item.code]) }}</div>
                                            </template>
                                        </el-autocomplete>
                                        <el-input v-else v-model="form.data.stock_code" type="text"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">
                                        {{ $t('pages.ecommerce.productManagement.product.save.cols.stock') }}
                                        <span v-if="form.updateStatus">({{ $t('pages.ecommerce.productManagement.product.save.soldOutCount') + ': ' + (form.data.sold_out_count ?? 0) }})</span>
                                    </label>
                                    <el-form-item prop="data.stock" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.stock" :min="1"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.purchasedNotification') }}</label>
                                    <el-form-item prop="data.usage_type">
                                        <el-select v-model="form.data.entity_settings.purchased_notification_id" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                                            <el-option v-for="(item, itemIndex) in notificationItems" :key="itemIndex" :value="(item.id.toString())" :label="item.name"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.sort') }}</label>
                                    <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.sort" :min="1"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.showInList') }}</label>
                                    <el-form-item prop="data.show_in_list">
                                        <el-radio-group v-model="form.data.show_in_list">
                                            <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.showInPopular') }}</label>
                                    <el-form-item prop="data.show_in_popular">
                                        <el-radio-group v-model="form.data.show_in_popular">
                                            <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.status') }}</label>
                                    <el-form-item prop="data.active">
                                        <el-radio-group v-model="form.data.active">
                                            <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mt-5">
                                    <el-form-item class="mb-0">
                                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                            <span v-if="form.loading" class="indicator-progress">
                                                {{ $t("messages.wait") }}
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus && selectedGroup.code == 'PACKAGE'" :label="$t('pages.ecommerce.productManagement.product.save.tabs.packageItemSettings')" name="packageItemSettings">
            <PackageItem :productID="productID" v-if="tabModel == 'packageItemSettings'"></PackageItem>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.ecommerce.productManagement.product.save.tabs.addonSettings')" name="addonSettings">
            <Addon :productID="productID" v-if="tabModel == 'addonSettings'"></Addon>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus && selectedGroup.supported_variant_group && selectedGroup.supported_variant_group.length" :label="$t('pages.ecommerce.productManagement.product.save.tabs.variantSettings')" name="variantSettings">
            <Variant :productID="productID" :productGroupID="selectedGroup.id" v-if="tabModel == 'variantSettings'"></Variant>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.ecommerce.productManagement.product.save.tabs.trailerSettings')" name="trailerSettings">
            <Trailer :productID="productID" v-if="tabModel == 'trailerSettings'"></Trailer>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus && customizePage.isCan" :label="$t('pages.ecommerce.productManagement.product.save.tabs.customizePageSettings')" name="customizePageSettings">
            <CustomizePage :productID="productID" :customizePage="customizePage.data" v-if="tabModel == 'customizePageSettings'"></CustomizePage>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus && ['PACKAGE', 'LESSON'].includes(product.group.code)" :label="$t('pages.ecommerce.productManagement.product.save.tabs.lessonCalendarSettings')" name="lessonCalendarSettings">
            <LessonCalendar :productID="productID" v-if="tabModel == 'lessonCalendarSettings'"></LessonCalendar>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus && canUseModuleList.includes('DOCUMENT')" :label="$t('pages.ecommerce.productManagement.product.save.tabs.documentSettings')" name="documentSettings">
            <Document documentableType="PRODUCT" :documentableID="productID" v-if="tabModel == 'documentSettings'"></Document>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.ecommerce.productManagement.product.save.tabs.invoiceProviderProductSettings')" name="invoiceProviderProductSettings">
            <InvoiceProviderProductRelation :productID="productID" autoLoad customBodyClass="w-100 w-md-50 m-auto" v-if="tabModel == 'invoiceProviderProductSettings'"></InvoiceProviderProductRelation>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import ElSelectTree from "@/components/ElSelectTree.vue";
import PackageItem from "@/components/ecommerce/product/PackageItem";
import Addon from "@/components/ecommerce/product/Addon";
import Trailer from "@/components/ecommerce/product/Trailer";
import Variant from "@/components/ecommerce/product/Variant";
import CustomizePage from "@/components/ecommerce/product/customize-page";
import CustomCkeditor from "@/components/custom-ckeditor"
import Document from "@/components/module/document";
import LessonCalendar from "@/components/ecommerce/product/lesson-calendar";
import InvoiceProviderProductRelation from "@/components/ecommerce/invoice/provider/product-relation";

export default {
    name: "_id",
    components: {
        ElSelectTree,
        PackageItem,
        Addon,
        Trailer,
        Variant,
        Document,
        CustomizePage,
        CustomCkeditor,
        LessonCalendar,
        InvoiceProviderProductRelation
    },
    data() {
        return {
            tabModel: 'general',
            form: {
                updateStatus: false,
                loading: false,
                translate: {
                    language_id: this.$root.defaultLanguage.id,
                    meta: {},
                    tags: []
                },
                supportedDetails: {},
                supportedOptions: {},
                data: {
                    taxes: [],
                    stock: 1,
                    sort: 1,
                    show_in_list: true,
                    show_in_popular: false,
                    options: [],
                    details: [],
                    prices: [{currency_id: this.$root.defaultCurrency.id}],
                    active: true,
                    entity_settings: {}
                }
            },
            rangeDates: {
                saleDate: undefined
            },
            tags: {
                inputVisible: false,
                inputValue: ''
            },
            product: {},
            treeCategories: [],
            image: {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            },
            shipping: {
                system: null,
                customPrice: undefined,
                freeShipping: 0,
                productWithoutShipping: -1
            },
            usageTypes: {
                fixed_date: 1,
                custom_day: 2
            },
            customizePage: {
                data: {},
                isCan: false
            },
            canUseModuleList: [],
            stockCodeAutoComplete: true,
        }
    },
    provide() {
        return {
            loadCustomizePage: this.loadCustomizePage
        }
    },
    computed: {
        productGroups() {
            return this.$store.state.ecommerce.productManagement.product.group.table.data;
        },
        productTypes() {
            return this.$store.state.ecommerce.productManagement.product.type.table.data;
        },
        examDates() {
            return this.$store.state.module.examDate.table.data;
        },
        lessonTypes() {
            return this.$store.state.module.lessonType.table.data;
        },
        teachers() {
            return this.$store.state.module.teacher.table.data;
        },
        taxes() {
            return this.$store.state.localization.tax.table.data;
        },
        notificationItems() {
            return this.$store.state.notification.item.table.data;
        },
        selectedGroup() {
            return this.resolveDatum(this.productGroups, this.form.data.group_id);
        },
        supportedOptionGroups() {
            return this.selectedGroup.supported_option_group ?? [];
        },
        supportedDetailGroups() {
            return this.selectedGroup.supported_detail_group ?? [];
        },
        productID() {
            return this.$route.params.id;
        },
        productTranslate() {
            return this.resolveDatum(this.product.translations, this.$root.defaultLanguage.id, 'language_id');
        }
    },
    created() {
        if (this.productID && !(this.productID > 0)) {
            this.$router.push({
                path: "/ecommerce/productManagement/product"
            });
        }
    },
    mounted() {
        this.$store.dispatch('ecommerce/productManagement/product/group/get', {});
        this.$store.dispatch('ecommerce/productManagement/product/type/get', {});
        this.$store.dispatch('module/examDate/get', {
            page: { pageSize: 9999 }
        });
        this.$store.dispatch('module/lessonType/get', {
            page: {pageSize: 9999}
        });
        this.$store.dispatch('module/teacher/get', {
            page: {pageSize: 9999}
        });
        this.$store.dispatch('localization/tax/get', {
            page: {pageSize: 9999}
        })
        this.$store.dispatch('notification/item/get', {
            page: {pageSize: 9999},
            filterData: {
                sub_group_code: "PRODUCT_PURCHASED"
            }
        })
        this.loadTreeCategories();
        if (this.productID && this.productID > 0) {
            this.loadProduct();
        }
    },
    methods: {
        onSubmit() {
            this.$refs.productForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.image.uploadList.length > 0) {
                        this.submitFormWithUpload();
                    } else {
                        if (!(this.image.fileList.length > 0)) {
                            this.form.data.image_id = null;
                        }
                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            const formData = new FormData();

            this.image.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.image_id = data[0].id;
                    this.image.uploadList = [];
                    this.submitForm();
                }, () => {
                    this.form.loading = false;
                }, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm() {
            let formData = this.prepareProductFormData();

            this.form.loading = true;

            if (this.form.data.id) {
                this.axios.put(this.endpoints['ecommerce_product'] + '/' + this.form.data.id, formData).then(response => {
                    this.onResponse(response.data, () => {}, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            } else {
                this.axios.post(this.endpoints['ecommerce_product'], formData).then(response => {
                    this.onResponse(response.data, () => {
                        this.$router.push({
                            path: "/ecommerce/product-management/product/save/" + response.data.data.id
                        });
                        this.loadProduct(response.data.data.id);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        loadProduct(productID = this.productID) {
            this.resetImageData();

            this.axios.get(this.endpoints['ecommerce_product'] + '/' + productID).then((response) => {
                let data = response.data.data;
                this.product = data;

                let translateLinks = this.resolveDatum(data.links, this.$root.defaultLanguage.id, 'language_id');

                data.taxes = data.taxes.map((tax) => {
                    return tax.id;
                });

                data.tags = data.tags.map((tag) => {
                    if (tag.pivot.language_id == this.$root.defaultLanguage.id) {
                        return tag.name;
                    }
                });

                this.form.shipping = this.findShipping(data.shipping);

                data.sale_min_date = data.sale_min_date ? this.$moment(data.sale_min_date).format('YYYY-MM-DD HH:mm:ss') : undefined;
                data.sale_max_date = data.sale_max_date ? this.$moment(data.sale_max_date).format('YYYY-MM-DD HH:mm:ss') : undefined;
                data.usage_min_date = data.usage_min_date ? this.$moment(data.usage_min_date).format('YYYY-MM-DD HH:mm:ss') : undefined;
                data.usage_max_date = data.usage_max_date ? this.$moment(data.usage_max_date).format('YYYY-MM-DD HH:mm:ss') : undefined;

                this.loadCustomizePage(data.customize_page_name);

                if (data.can_use_module) {
                    this.loadCanUseModuleList();
                }

                if(Array.isArray(data.entity_settings)){
                    data.entity_settings = {};
                }

                this.form.translate = Object.assign(this.productTranslate, {
                    meta: {
                        title: translateLinks.title,
                        description: translateLinks.description,
                        keyword: translateLinks.keyword
                    },
                    tags: data.tags
                });
                this.form.updateStatus = true;
                this.form.data = data;

                if (data.image) {
                    this.image.fileList = [
                        {
                            name: data.image.name,
                            url: data.image.public_url
                        }
                    ]
                }
            })
        },
        loadTreeCategories() {
            this.axios.get(this.endpoints['ecommerce_product_category'] + '/tree').then((response) => {
                this.treeCategories = response.data.data;
            });
        },
        handleTagClose(tag) {
            this.form.translate.tags.splice(this.form.translate.tags.indexOf(tag), 1)
        },
        showTagInput() {
            this.tags.inputVisible = true
            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus()
            })
        },
        handleTagInputConfirm() {
            const inputValue = this.tags.inputValue
            if (inputValue) {
                this.form.translate.tags.push(inputValue)
            }
            this.tags.inputVisible = false
            this.tags.inputValue = ''
        },
        disabledSaleMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.form.data.sale_max_date ? this.$moment(this.form.data.sale_max_date) : undefined;

            return endDate != undefined && startDate.valueOf() > endDate.valueOf();
        },
        disabledSaleMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.form.data.sale_min_date ? this.$moment(this.$moment(this.form.data.sale_min_date).format('YYYY-MM-DD')) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
        disabledUsageMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.form.data.usage_max_date ? this.$moment(this.form.data.usage_max_date) : undefined;

            return endDate != undefined && startDate.valueOf() > endDate.valueOf();
        },
        disabledUsageMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.form.data.usage_min_date ? this.$moment(this.$moment(this.form.data.usage_min_date).format('YYYY-MM-DD')) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
        prepareProductFormData() {
            let formData = this.form.data;

            if (formData.details) {
                for (let i = 0; i < formData.details.length; i++) {
                    if (formData.details[i].val == undefined || !formData.details[i].val.trim().length) {
                        formData.details.splice(i, 1);
                    }
                }
            }

            let newDetails = [];
            for (const [detailIndex, detail] of Object.entries(this.form.supportedDetails)) {
                if (detail.val != undefined && detail.val.trim().length) {
                    newDetails.push(detail);
                }
            }
            formData.details = newDetails;

            let newOptions = [];
            for (const [optionIndex, option] of Object.entries(this.form.supportedOptions)) {
                if (option) {
                    newOptions.push(option);
                }
            }
            formData.options = newOptions;

            if (formData.type_id != 2) {
                delete formData.usage_min_date;
                delete formData.usage_max_date;
            }

            if (formData.shipping_weight && formData.shipping_weight.length && formData.shipping == -1) {
                delete formData.shipping_weight;
            }

            return {...this.form.translate, ...formData}
        },
        resetImageData() {
            this.image = {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        },
        handlePictureCardPreview(file) {
            this.image.dialogImageUrl = file.url
            this.image.dialogVisible = true
        },
        handleChangeImage(file, fileList) {
            this.image.fileList = fileList;
            this.image.uploadList = fileList;
        },
        getOptionModelValue(type, items, defaultValue = undefined) {
            let formItems = this.form.data[type];
            let formItemValues = formItems;

            if (type == 'details') {
                formItemValues = formItems.map((detail) => {
                    return detail.item_id;
                });
            }

            for (let i = 0; i < items.length; i++) {
                let x = formItemValues.indexOf(items[i].id);
                if (x !== -1) {
                    return formItems[x];
                }
            }

            return defaultValue;
        },
        findShipping(value) {
            for (let shippingCode in this.shipping) {
                let shippingValue = this.shipping[shippingCode];
                if (shippingValue == value) {
                    return shippingCode;
                }
            }

            return 'customPrice';
        },
        loadCustomizePage(customizePageName) {
            if (customizePageName) {
                this.axios.get(this.endpoints['theme_customize'] + '/' + customizePageName).then((response) => {
                    if (response.data.status) {
                        this.customizePage = {
                            data: response.data.data,
                            isCan: true
                        };
                    } else {
                        this.customizePage = {
                            data: {},
                            isCan: false
                        };
                    }
                });
            } else {
                this.customizePage = {
                    data: {},
                    isCan: false
                };
            }
        },
        loadCanUseModuleList() {
            this.axios.get(this.endpoints['ecommerce_product_operation'] + '/' + this.productID).then((response) => {
                let data = response.data.data;
                this.canUseModuleList = data.map((item) => {
                    return item.code;
                });
            })
        },
        remoteStockCode(queryString, cb){
            this.axios.get(this.endpoints['ecommerce_invoice_provider_util_stock_list'] + '/' + 0, {
                params: {
                    name: queryString
                }
            }).then((response) => {
                let data = response.data.data;

                cb(data);
            }).catch(error => {
                let data = error.response.data;

                if(data.message == "provider_not_support_stock_list") {
                    this.stockCodeAutoComplete = false;
                } else {
                    cb([]);
                }
            });
        }
    },
    watch: {
        'form.data.usage_max_day': function (newValue) {
            if (newValue) {
                this.form.data.usage_min_date = '';
                this.form.data.usage_max_date = '';
            }
        }
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.el-tag {
    margin-right: 10px;
}

.button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
}

.input-new-tag .el-input__inner {
    height: 32px;
    line-height: 32px;
}

.rangeDateTimePickerPopper .el-button--text.el-picker-panel__link-btn {
    display: none;
}
</style>