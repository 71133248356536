<template>
    <custom-table
        :title="$t('pages.ecommerce.productManagement.product.trailerSettings.title')"
        :subTitle="$t('pages.ecommerce.productManagement.product.trailerSettings.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow">
        <template v-slot:trailer_type="{ row: record }">
            {{ $t('pages.ecommerce.productManagement.product.trailerSettings.trailerTypes.' + record.trailer_type) }}
        </template>
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchTrailer(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_trailer" ref="addTrailerModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="trailerForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.trailerSettings.cols.trailerType') }}</label>
                                <el-form-item prop="trailer_type" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.trailer_type" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(trailerType, trailerTypeIndex) in trailerTypes" :key="trailerTypeIndex" :value="trailerType" :label=" $t('pages.ecommerce.productManagement.product.trailerSettings.trailerTypes.' + trailerType)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.trailerSettings.cols.providerData.api') }}</label>
                                <el-form-item prop="provider_data.api" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.provider_data.api" class="w-100" :placeholder="$t('common.chooseSelect')" @change="resetFormProviderData">
                                        <el-option v-for="(providerApi, providerApiIndex) in providerApi" :key="providerApiIndex" :value="providerApi" :label="providerApi">{{ providerApi }}</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <template v-if="form.data.provider_data.api == 'ADOBE_CONNECT'">
                                <div class="fv-row mb-7">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.trailerSettings.cols.providerData.url') }}</label>
                                    <el-form-item prop="provider_data.url" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.data.provider_data.url" type="text"/>
                                    </el-form-item>
                                </div>
                            </template>

                            <template v-if="['VIMEO', 'VISTREAM', 'YOUTUBE'].includes(form.data.provider_data.api)">
                                <div class="fv-row mb-7">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.trailerSettings.cols.providerData.videoId') }}</label>
                                    <el-form-item prop="provider_data.video_id" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.data.provider_data.video_id" type="text"/>
                                    </el-form-item>
                                </div>

                               <!--<div class="fv-row mb-7">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.trailerSettings.cols.providerData.server') }}</label>
                                    <el-form-item prop="provider_data.server">
                                        <el-input v-model="form.data.provider_data.server" type="text"/>
                                    </el-form-item>
                                </div>-->
                            </template>

                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    props: ['productID'],
    name: "Trailer",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.productManagement.product.trailerSettings.cols.id"),
                    key: "id"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.trailerSettings.cols.trailerType"),
                    scopedSlots: {customRender: "trailer_type"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.trailerSettings.cols.providerData.api"),
                    key: "provider_data.api"
                },
                {
                    name: this.$t('pages.ecommerce.productManagement.product.trailerSettings.cols.createdAt'),
                    scopedSlots: {customRender: "created_at"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {
                    provider_data: {}
                }
            },
            selectedRowKeys: [],
            table: {
                data: [],
                loading: false
            },
            trailerTypes: ['main'],
            providerApi: ['VIMEO', 'YOUTUBE'] //'ADOBE_CONNECT', 'VISTREAM'
        }
    },
    computed: {
        checkedTrailerTypes() {
            let types = this.cloneData(this.trailerTypes);

            this.table.data.forEach((item) => {
                let x = types.indexOf(item.trailer_type);
                if(x !== -1){
                    types.splice(x, 1);
                }
            });

            return types;
        },
        actions() {
            let actions = [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ];

            if (this.checkedTrailerTypes.length > 0) {
                actions.unshift({
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                });
            }

            return actions;
        },
    },
    mounted(){
        this.loadProduct();
    },
    methods:{
        loadProduct(){
            this.table.loading = true;

            this.axios.get(this.endpoints['ecommerce_product'] + '/' + this.productID).then((response) => {
                let data = response.data.data;
                this.table.data = data.trailers;
            }).finally(() => {
                this.table.loading = false;
            });
        },
        newTrailer(){
            this.form.updateStatus = false;
            this.form.data = {
                product_id: this.productID,
                provider_data: {}
            };
            this.form.title = this.$t("pages.ecommerce.productManagement.product.trailerSettings.newTrailer");
            this.showModal(this.$refs.addTrailerModal);
        },
        fetchTrailer(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.ecommerce.productManagement.product.trailerSettings.editTrailer");
            this.axios.get(this.endpoints['ecommerce_product_trailer']+ '/' + record.id).then(response => {
                let data = response.data.data;
                this.form.data = data;
                this.showModal(this.$refs.addTrailerModal);
            });
        },
        onSubmit(){
            this.$refs.trailerForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    this.form.data.provider_data.server = 1;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['ecommerce_product_trailer'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadProduct();
                                this.hideModal(this.$refs.addTrailerModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            if (error.response.data.message == 'same_trailer_type_already_exists') {
                                error.response.data.message = this.$t('pages.ecommerce.productManagement.product.trailerSettings.responseMessages.sameTrailerTypeAlreadyExists');
                            }

                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoints['ecommerce_product_trailer'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadProduct();
                                this.hideModal(this.$refs.addTrailerModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            if (error.response.data.message == 'same_trailer_type_already_exists') {
                                error.response.data.message = this.$t('pages.ecommerce.productManagement.product.trailerSettings.responseMessages.sameTrailerTypeAlreadyExists');
                            }

                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id){
            this.$store.dispatch("ecommerce/productManagement/product/trailer/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if(successDeleted){
                    this.loadProduct();
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadProduct();
                    break;

                case "new":
                    this.newTrailer();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        resetFormProviderData(){
            let providerData = {
                api: this.form.data.provider_data.api
            };

            this.form.data.provider_data = providerData;
        }
    }
}
</script>

<style scoped>

</style>