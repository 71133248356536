<template>
    <div class="card" v-if="skeleton">
        <div class="card-body d-flex flex-column p-9">
            <el-skeleton />
        </div>
    </div>
    <custom-table
        v-else
        :title="$t('pages.ecommerce.productManagement.product.addonSettings.title')"
        :subTitle="$t('pages.ecommerce.productManagement.product.addonSettings.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow">
        <template v-slot:title="{ row: record }">
            <a class="fw-bold text-gray-600 text-hover-primary" :href="$root.appUrl + '/' + record.product.defaultLanguageTranslate.slug" target="_blank">{{ record.product.defaultLanguageTranslate.title }}</a>
        </template>
        <template v-slot:price="{ row: record }">
            {{ record.defaultCurrencyPrice.price ? record.defaultCurrencyPrice.price +  " " + $root.defaultCurrency.suffix : "-" }}
        </template>
        <template v-slot:discountPrice="{ row: record }">
            {{ record.defaultCurrencyPrice.discount_price ? record.defaultCurrencyPrice.discount_price + " " + $root.defaultCurrency.suffix : "-" }}
        </template>
        <template v-slot:autoAddCart="{ row: record }">
            <span :class="record.auto_add_cart ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.auto_add_cart ? $t('common.yes') : $t('common.no') }}</span>
        </template>
        <template v-slot:cannotRemoveCart="{ row: record }">
            <span :class="record.cannot_remove_cart ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.cannot_remove_cart ? $t('common.yes') : $t('common.no') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchAddon(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_addon" ref="addAddonModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="addonForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7" v-if="!form.updateStatus">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.addonSettings.cols.addon') }}</label>
                                <el-form-item prop="addon_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.addon_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable>
                                        <el-option v-for="(addon, addonIndex) in addableAddons" :key="addonIndex" :value="addon.id" :label="addon.translate.title + ' (' + addon.group.name + ')'"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="row">
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">
                                        {{ $t('pages.ecommerce.productManagement.product.addonSettings.cols.price') }}
                                        <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.productManagement.product.addonSettings.informationBoxes.price')" placement="top">
                                            <span class="svg-icon svg-icon-1">
                                                <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                            </span>
                                        </el-tooltip>
                                    </label>
                                    <el-form-item prop="prices[0].price">
                                        <el-input v-model="form.data.prices[0].price" oninput="value=value.replace(/[^0-9.]/g,'')"  />
                                    </el-form-item>
                                </div>
                                <div class="col-md-6 fv-row mb-1" v-if="form.data.prices[0].price && form.data.prices[0].price.length">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.addonSettings.cols.discountPrice') }}</label>
                                    <el-form-item prop="prices[0].discount_price">
                                        <el-input v-model="form.data.prices[0].discount_price" oninput="value=value.replace(/[^0-9.]/g,'')" />
                                    </el-form-item>
                                </div>

                                <div class="col-md-6 fv-row mb-1" v-if="form.data.prices[0].price && form.data.prices[0].price.length">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.addonSettings.cols.invoicePrice') }}</label>
                                    <el-form-item prop="prices[0].invoice_price">
                                        <el-input v-model="form.data.prices[0].invoice_price" oninput="value=value.replace(/[^0-9.]/g,'')" />
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.addonSettings.cols.quantity') }}</label>
                                <el-form-item prop="quantity" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.quantity" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.addonSettings.cols.autoAddCart') }}</label>
                                <el-form-item prop="auto_add_cart">
                                    <el-radio-group v-model="form.data.auto_add_cart" @change="changedAutoAddCart">
                                        <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                        <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7" v-if="form.data.auto_add_cart">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.addonSettings.cols.cannotRemoveCart') }}</label>
                                <el-form-item prop="cannot_remove_cart">
                                    <el-radio-group v-model="form.data.cannot_remove_cart">
                                        <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                        <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>

                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    props: ['productID'],
    name: "Addon",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.productManagement.product.addonSettings.cols.id"),
                    key: "id"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.title"),
                    scopedSlots: {customRender: "title"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.addonSettings.cols.quantity"),
                    key: "quantity"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.group"),
                    key: "product.group.name"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.type"),
                    key: "product.type.name"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.price"),
                    scopedSlots: {customRender: "price"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.discountPrice"),
                    scopedSlots: {customRender: "discountPrice"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.addonSettings.cols.autoAddCart"),
                    scopedSlots: {customRender: "autoAddCart"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.addonSettings.cols.cannotRemoveCart"),
                    scopedSlots: {customRender: "cannotRemoveCart"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {
                    prices: [{currency_id: this.$root.defaultCurrency.id}],
                }
            },
            selectedRowKeys: [],
            table: {
                data: [],
                loading: false
            },
            skeleton: true,
        }
    },
    computed: {
        addons() {
            let addons = [];
            let products = this.$store.state.ecommerce.productManagement.product.table.data;

            for(let i = 0; i < products.length; i++) {
                products[i].translate = this.resolveDatum(products[i].translations, this.$root.defaultLanguage.id, 'language_id');
                addons.push(products[i]);
            }

            return addons;
        },
        addableAddons(){
            let addableAddons = [];
            let addons = this.addons;
            let addedAddonIDs = this.table.data.map((data) => {
                return data.product_addon_id;
            });

            for(let i = 0; i < addons.length; i++) {
                if(!addedAddonIDs.includes(addons[i].id) &&
                    this.addons[i].id != this.productID &&
                    this.addons[i].group.code != 'PACKAGE' &&
                    this.addons[i].addons_count == 0 &&
                    this.addons[i].package_items_count == 0 &&
                    this.addons[i].variant_combinations_count == 0){
                    addableAddons.push(addons[i]);
                }
            }

            return addableAddons;
        }
    },
    mounted(){
        this.loadProduct();
        this.$store.dispatch('ecommerce/productManagement/product/get', {
            page: { pageSize: 9999 },
            filterData: {
                just_product: true
            }
        }).then(() => {
            this.skeleton = false;
        });
    },
    methods:{
        loadProduct(){
            this.table.loading = true;

            this.axios.get(this.endpoints['ecommerce_product'] + '/' + this.productID).then((response) => {
                let data = response.data.data;
                this.table.data = data.addons.map((addon) => {
                    addon.product.defaultLanguageTranslate =  this.resolveDatum(addon.product.translations, this.$root.defaultLanguage.id, 'language_id');
                    addon.defaultCurrencyPrice =  (addon.prices && addon.prices.length) ? this.resolveDatum(addon.prices, this.$root.defaultCurrency.id, 'currency_id') : {};

                    return addon;
                });
            }).finally(() => {
                this.table.loading = false;
            });
        },
        newAddon(){
            this.form.updateStatus = false;
            this.form.data = {
                auto_add_cart: false,
                cannot_remove_cart: false,
                product_id: this.productID,
                prices: [{currency_id: this.$root.defaultCurrency.id}],
                quantity: 1
            };
            this.form.title = this.$t("pages.ecommerce.productManagement.product.addonSettings.newAddon");
            this.showModal(this.$refs.addAddonModal);
        },
        fetchAddon(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.ecommerce.productManagement.product.addonSettings.editAddon");
            this.axios.get(this.endpoints['ecommerce_product_addon']+ '/' + record.id).then(response => {
                let data = response.data.data;
                data.prices = data.prices.length ? data.prices : [{currency_id: this.$root.defaultCurrency.id}];
                this.form.data = data;
                this.showModal(this.$refs.addAddonModal);
            });
        },
        onSubmit(){
            this.$refs.addonForm.validate((valid) => {
                if(valid) {
                    let formData = this.prepareAddonFormData();
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['ecommerce_product_addon'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadProduct();
                                this.hideModal(this.$refs.addAddonModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoints['ecommerce_product_addon'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadProduct();
                                this.hideModal(this.$refs.addAddonModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id){
            this.$store.dispatch("ecommerce/productManagement/product/addon/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if(successDeleted){
                    this.loadProduct();
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadProduct();
                    break;

                case "new":
                    this.newAddon();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        prepareAddonFormData(){
            let formData = JSON.parse(JSON.stringify(this.form.data));

            if(!(formData.prices[0].price && formData.prices[0].price.length)){
                formData.prices = [];
            }

            return formData;
        },
        changedAutoAddCart($event){
            if(!this.form.data.auto_add_cart) {
                this.form.data.cannot_remove_cart = false;
            }
        }
    }
}
</script>

<style scoped>

</style>