<template>
    <div v-if="typeof(item) == 'object' && !Array.isArray(item)" v-bind:style="{'margin-left': ((depth * 10) + 'px')}">
        <div v-for="(itemValue, itemKey, itemIndex) in item" :key="itemIndex">
            <div v-if="itemValue.type">
                <div class="fv-row mb-1">
                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.customizePageSettings.fields.' + itemKey) }}</label>
                    <div class="mb-2" v-if="itemValue.type == 'string'">
                        <el-input type="text" v-model="itemValue.value"/>
                    </div>
                    <div class="mb-2" v-if="itemValue.type == 'image'">
                        <el-upload
                            :accept="$root.acceptFileSupported('image', 'image/')"
                            v-bind:class="{'disabled-upload': getProvide('images', itemValue.value.unique).fileList.length > 0}"
                            :on-change="(file, fileList) => { return this.handleChangeImage(file, fileList, itemKey, itemValue.value.unique); }"
                            :on-remove="(file, fileList) => { return this.handleChangeImage(file, fileList, itemKey, itemValue.value.unique); }"
                            list-type="picture-card"
                            :file-list="getProvide('images', itemValue.value.unique).fileList"
                            :limit="1"
                            action="#"
                            :on-preview="(file) => { return this.handlePictureCardPreview(file, itemValue.value.unique); }"
                            :auto-upload="false">
                            <i class="bi bi-plus" />
                        </el-upload>
                        <el-dialog v-model="getProvide('images', itemValue.value.unique).dialogVisible" width="30%">
                            <img style="width: 100%" :src="getProvide('images', itemValue.value.unique).dialogImageUrl" alt="" />
                        </el-dialog>
                    </div>
                    <div class="mb-2" v-if="itemValue.type == 'editor'">
                        <CustomCkeditor :model="itemValue.value"  @updateModel="itemValue.value = $event"></CustomCkeditor>
                    </div>
                </div>
            </div>
            <Item :item="itemValue" :itemKey="itemKey" :schema="schema[itemKey]" v-else :depth="depth"></Item>
        </div>
    </div>
    <div v-if="Array.isArray(item)">
        <div class="d-flex flex-wrap flex-stack mt-7 my-5 justify-content-between">
            <h4 class="fw-bolder mb-1">{{ $t('pages.ecommerce.productManagement.product.customizePageSettings.fields.' + itemKey) }}</h4>
            <button type="button" class="btn btn-sm btn-primary fs-6" v-on:click="addItem">
                <span class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/arrows/arr009.svg" />
                </span>
                {{ $t('btn.add') }}
            </button>
        </div>
        <div class="mt-2" v-for="(child, childIndex) in item" :key="childIndex">
            <div class="d-flex justify-content-between align-items-end">
                <h5 class="fw-bolder mb-3" v-bind:class="{'mt-6': childIndex != 0}" v-bind:style="{'margin-left': (((depth + 1) * 10) + 'px')}">
                    <span v-if="item.length > 1">{{ (childIndex + 1) }}.</span>
                </h5>
                <div>
                    <button type="button" class="btn btn-sm btn-danger" v-on:click="removeItem(childIndex)">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/arrows/arr011.svg" />
                        </span>
                        {{ $t('btn.' +  (item.length > 1 ?  'delete' : 'clear')) }}
                    </button>
                </div>
            </div>
            <Item :item="child" :itemKey="(childIndex + 1)" :key="childIndex" :schema="schema[0]" :depth="depth + 1"></Item>
        </div>
    </div>
</template>

<script>
import Item from "@/components/ecommerce/product/customize-page/item";
import CustomCkeditor from "@/components/custom-ckeditor"

export default {
    name: "item",
    props: ['item', 'itemKey', 'schema', 'depth'],
    inject: ['getProvide', 'setProvide', 'deleteProvide'],
    components:{
        Item,
        CustomCkeditor
    },
    data() {
       return {}
    },
    created(){
        for (let index in this.item){
            if(this.item[index].type && this.item[index].type == 'image'){
                let unique = 'id' + (new Date()).getTime();
                this.item[index].value.unique = unique;

                this.setProvide('images', unique, {
                    dialogImageUrl: '',
                    dialogVisible: false,
                    fileList: this.item[index].value.image_id ? [ {
                        name: index,
                        url: this.$root.appUrl + '/' +  this.item[index].value.image.original
                    }] : []
                })
            }
        }
    },
    methods: {
        addItem(){
            this.item.push(JSON.parse(JSON.stringify(Array.isArray(this.schema) ? this.schema[0] : this.schema)))
        },
        removeItem(index){
            this.item.splice(index, 1);

            if(this.item.length == 0) {
                this.$nextTick(() => {
                    this.addItem();
                });
            }
        },
        handlePictureCardPreview(file, unique) {
            this.setProvide('images', unique, file.url, 'dialogImageUrl');
            this.setProvide('images', unique, true, 'dialogVisible');
        },
        getBase64(file) {
            return new Promise(function (resolve, reject) {
                const reader = new FileReader()
                let imgResult = ''
                reader.readAsDataURL(file)
                reader.onload = function () {
                    imgResult = reader.result
                }
                reader.onerror = function (error) {
                    reject(error)
                }
                reader.onloadend = function () {
                    resolve(imgResult)
                }
            })
        },
        handleChangeImage(file, fileList, imageKey, unique){
            if(fileList.length) {
                this.getBase64(file.raw).then(res => {
                    const params = res.split(',')

                    if (params.length > 0) {
                        this.setProvide('images', unique, [{
                            name: file.name,
                            url: 'data:image/jpeg;base64,' + params[1]
                        }], 'fileList');
                    }
                });
                this.setProvide('uploadImages', unique, fileList);
            } else {
                this.item[imageKey].value.image_id = null;
                this.deleteProvide('uploadImages', unique);
                this.setProvide('images', unique, [], 'fileList');
            }
        }
    }
}
</script>

<style scoped>

</style>